import clsx from "clsx";
import React, { FC } from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
  display?: boolean;
};

export const FormRow: FC<Props> = ({ children, className, display }) => {
  return <div className={clsx("flex items-center", display === false ? "hidden" : "", className)}>{children}</div>;
};
