import actions from "../../actions";
import * as constants from "../../constants";
import { changeLanguage } from "../../i18n";

import storageManager from "@/lib/storageManager";

const gotoHome = (store, authResult) => {
  localStorage.setItem("authenticatedData", JSON.stringify(authResult));
  const defaultSystem = storageManager.getUserItem("system") ?? "progress";
  const state = store.getState();
  const lang = state.app.lang;
  changeLanguage(lang);

  store.dispatch({
    type: constants.LOGIN_SUCCESS,
    payload: {
      system: defaultSystem,
      ...authResult,
    },
  });
};

const handleAuth = (store, action) => {
  if (action.payload.two_step_auth === 1) {
    store.dispatch({
      type: constants.LOGIN_SHOW_TWO_FACTOR_AUTH,
    });

    return;
  } else {
    if (action.payload.password_reset === 1) {
      store.dispatch({
        type: constants.LOGIN_SHOW_PASSWORD_EDITOR,
      });

      return;
    }

    if (action.payload.password_caution === 1) {
      store.dispatch({
        type: constants.LOGIN_SHOW_PASSWORD_WARNING,
      });

      return;
    }
  }

  gotoHome(store, action.payload);
};

export default {
  [constants.LOGIN_END_AUTH]: (store, action) => {
    handleAuth(store, action);
  },
  [constants.LOGIN_END_NEXT_AUTH]: (store, action) => {
    handleAuth(store, action);
  },
  [constants.LOGIN_HIDE_PASSWORD_WARNING]: (store, action) => {
    const { authResult } = store.getState().login;

    if (authResult.password_reset === 0) {
      gotoHome(store, authResult);

      return;
    }

    if (authResult.password_reset === 1) {
      store.dispatch({
        type: constants.LOGIN_SHOW_PASSWORD_EDITOR,
      });

      return;
    }
  },
  [constants.LOGIN_END_UPDATE_PASSWORD]: (store, action) => {
    const { authResult } = store.getState().login;
    gotoHome(store, authResult);
  },
  [constants.LOGIN_END_TWO_FACTOR_AUTH]: (store, action) => {
    const { name, password } = store.getState().login;
    store.dispatch(actions.login.nextAuth(name, password));
  },
  [constants.LOGIN_FAILED_TWO_FACTOR_AUTH]: (store, action) => {
    const { failedCount } = store.getState().login;
    store.dispatch({
      type: constants.APP_SHOW_ERROR,
      payload: action.payload,
    });
    if (failedCount === 3) {
      store.dispatch({
        type: constants.LOGIN_RESET_STATE,
      });
    }
  },
};
