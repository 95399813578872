import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { isValidRole } from "@/lib/roleChecker";
import { ConstructionMasters } from "@/models/masters";
import { MatrixColumnKey } from "@/models/matrix";
import { RootState } from "@/reducers/types";

type Props = {
  taskRoles: number[];
  showSpecialProcessBundle: () => void;
  showProtectionBundle: () => void;
};

const LeftGridHeaderChiba: FC<Props> = (props) => {
  const { t } = useTranslation();

  const { taskRoles, showSpecialProcessBundle, showProtectionBundle } = props;
  const canBulkUpdate = isValidRole(taskRoles, 0);

  // 表示設定
  const { configColumns } = useSelector((state: RootState) => state.matrix);
  const show = useCallback((target): boolean => !configColumns || configColumns?.[target], [configColumns]);

  // 機器分類名・元請名
  const { categoryId, primaryChargeId } = useSelector((state: RootState) => state.matrix?.currentConditions);
  const masters: ConstructionMasters = useSelector((state: RootState) => state.construction?.masters);
  const [categoryName, primaryChargeName] = useMemo(() => {
    if (!categoryId || !primaryChargeId || !masters?.categories || !masters?.primary_charges) {
      return ["", ""];
    }

    return [
      masters.categories.find((v) => v.category_id === categoryId)?.category_name ?? "",
      masters.primary_charges.find((v) => v.primary_charge_id === primaryChargeId)?.primary_charge_name ?? "",
    ];
  }, [categoryId, primaryChargeId, masters?.categories, masters?.primary_charges]);

  const [conditionLabelStyle, setConditionLabelStyle] = useState<{ maxWidth?: string; minWidth?: string }>();
  const tableRef = useRef<HTMLTableElement>();
  useEffect(() => {
    const minWidth = 200;
    const margin = 20;
    if (tableRef.current?.offsetWidth < minWidth + margin) {
      // テーブルの幅が狭い場合は表示幅も小さくする
      setConditionLabelStyle({ maxWidth: `${tableRef.current?.offsetWidth - margin}px` });
    } else {
      // テーブル幅に余裕があるときは基本的には200pxで表示する
      setConditionLabelStyle({ minWidth: `${minWidth}px`, maxWidth: `${tableRef.current?.offsetWidth - margin}px` });
    }
  }, [configColumns]);

  return (
    <table className="grid-table grid-table-header grid-table-left-chiba grid-table-left-header chiba" ref={tableRef}>
      <thead>
        <tr>
          {show(MatrixColumnKey.No) && (
            <th className="col-no">
              <span className="grid-cell-narrow">No</span>
            </th>
          )}
          {show(MatrixColumnKey.DeliveryFlg) && (
            <th className="col-delivery-flg rotate">
              <span className="grid-cell-narrow">{t("delivery")}</span>
            </th>
          )}
          {show(MatrixColumnKey.ItemComplete) && (
            <th className="col-item-complete rotate">
              <span className="grid-cell-narrow">{t("complete_process")}</span>
            </th>
          )}
          {show(MatrixColumnKey.Totaltest) && (
            <th className="col-totaltest rotate">
              <span className="grid-cell-narrow">{t("airtight_preparation")}</span>
            </th>
          )}
          {show(MatrixColumnKey.Area) && <th className="col-area">{t("area")}</th>}
          {show(MatrixColumnKey.Device) && <th className="col-device">{t("device")}</th>}
          {show(MatrixColumnKey.PrimaryCharge) && <th className="col-primary-charge">{t("primary_charge")}</th>}
          {show(MatrixColumnKey.DocumentNo) && <th className="col-document-no">{t("construction_specification")}No</th>}
          <th className="col-item">{t("title")}</th>
          {show(MatrixColumnKey.SpecialWork) && <th className="col-special-work">{t("special_work")}</th>}
          {show(MatrixColumnKey.Protection) && <th className="col-protection">{t("protection")}</th>}
          {show(MatrixColumnKey.Regulation) && <th className="col-regulation">{t("law")}</th>}
          {show(MatrixColumnKey.Systems) && <th className="col-systems">{t("system")}</th>}
          {show(MatrixColumnKey.Note) && <th className="col-note">{t("note")}</th>}
          {show(MatrixColumnKey.User) && <th className="col-user">{t("assignee")}</th>}
        </tr>
        <tr className="grid-separator-bg">
          {show(MatrixColumnKey.No) && <th className="grid-separator" />}
          {show(MatrixColumnKey.DeliveryFlg) && <th className="grid-separator" />}
          {show(MatrixColumnKey.ItemComplete) && <th className="grid-separator" />}
          {show(MatrixColumnKey.Totaltest) && <th className="grid-separator" />}
          {show(MatrixColumnKey.Area) && <th className="grid-separator" />}
          {show(MatrixColumnKey.Device) && <th className="grid-separator" />}
          {show(MatrixColumnKey.PrimaryCharge) && <th className="grid-separator" />}
          {show(MatrixColumnKey.DocumentNo) && <th className="grid-separator" />}
          <th className="grid-separator" />
          {show(MatrixColumnKey.SpecialWork) && (
            <th
              className={`grid-separator ${canBulkUpdate ? "grid-cell-clickable" : ""}`}
              onClick={() => {
                if (!canBulkUpdate) {
                  return;
                }
                showSpecialProcessBundle();
              }}
            >
              <React.Fragment>
                {canBulkUpdate && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
              </React.Fragment>
            </th>
          )}
          {show(MatrixColumnKey.Protection) && (
            <th
              className={`grid-separator ${canBulkUpdate ? "grid-cell-clickable" : ""}`}
              onClick={() => {
                if (!canBulkUpdate) {
                  return;
                }
                showProtectionBundle();
              }}
            >
              <React.Fragment>
                {canBulkUpdate && <button className="grid-icon grid-icon-edit icon-mode_edit"></button>}
              </React.Fragment>
            </th>
          )}
          {show(MatrixColumnKey.Regulation) && <th className="grid-separator" />}
          {show(MatrixColumnKey.Systems) && <th className="grid-separator" />}
          {show(MatrixColumnKey.Note) && <th className="grid-separator" />}
          {show(MatrixColumnKey.User) && <th className="grid-separator" />}
        </tr>
        {categoryName && primaryChargeName && (
          <tr className="header-label" style={conditionLabelStyle}>
            <td>
              <span>{`${categoryName}：${primaryChargeName}`}</span>
            </td>
          </tr>
        )}
      </thead>
    </table>
  );
};

export default LeftGridHeaderChiba;
