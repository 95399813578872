import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "../../Modal";

import { CheckBox } from "@/components/common/CheckBox";
import { SubmitButton } from "@/components/common/SubmitButton";
import { SearchConditionConfigDefinition } from "@/models/screenConfig";

type Props<T> = {
  title: string;
  configMap: Map<string, boolean>;
  definitions: SearchConditionConfigDefinition<T>[];
  onClose: () => void;
  onSubmit: (config: Map<string, boolean>) => void;
};

export const SearchConditionConfigModal = <T,>(props: Props<T>) => {
  const { t } = useTranslation();

  const [values, setValues] = useState<Map<string, boolean>>(props.configMap);

  const handleChange = (key, checked) => {
    const v = new Map(values);
    v.set(key, checked);
    setValues(v);
  };

  const handleSubmit = () => {
    props.onSubmit(values);
    props.onClose();
  };

  return (
    <Modal title={props.title} closeHandler={props.onClose}>
      <div className="modal-body">
        <div>
          <div className="text-center">{t("search_condition_config_description")}</div>
          <div className="mt-[12px] mx-[36px]">
            {props.definitions.map((row, i) => (
              <div
                key={i}
                className="grid gap-x-[12px] gap-y-[16px] my-[16px]"
                style={{ gridTemplateColumns: [...Array(row.itemsPerLine)].map(() => "1fr").join(" ") }}
              >
                {row.items.map((item, j) => (
                  <div key={j}>
                    <CheckBox
                      checked={item.required || !!values.get(item.key as string)}
                      label={item.label}
                      onChange={(checked) => handleChange(item.key, checked)}
                      disabled={item.required}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          data-test-id="button-search-panel-cancel"
          type="button"
          className="btn btn-gray"
          onClick={props.onClose}
        >
          {t("cancel")}
        </button>
        <SubmitButton onClick={handleSubmit} />
      </div>
    </Modal>
  );
};
