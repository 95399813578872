import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import storageManager, { LocalStorageKeys } from "../lib/storageManager";

import { fetchScreenConfig, updateScreenConfig } from "@/actions/screenConfig";
import { ConfigType, ScreenNameType, SearchConditionConfig } from "@/models/screenConfig";

type Props<T> = {
  screenName: ScreenNameType;
  requiredKeys: T[];
};

export const useSearchConditionConfig = <T extends string>({ screenName, requiredKeys }: Props<T>) => {
  const dispatch = useDispatch();

  /* 隠したい条件の表示/非表示 */
  const [showAllConditions, setShowAllConditions] = useState(
    storageManager.getConstructionItem(LocalStorageKeys.displayAllConditions) !== "false"
  );
  const toggleDisplayOptionalConditions = () => {
    if (showAllConditions) {
      storageManager.setConstructionItem(LocalStorageKeys.displayAllConditions, "false");
      setShowAllConditions(false);
    } else {
      storageManager.setConstructionItem(LocalStorageKeys.displayAllConditions, "true");
      setShowAllConditions(true);
    }
  };

  /* 検索条件設定モーダル */
  const [showSettingModal, setShowSettingModal] = useState(false);
  const closeSettingModal = () => setShowSettingModal(false);
  const openSettingModal = () => setShowSettingModal(true);

  /* 検索条件設定（検索項目の表示/非表示の設定） */
  const [config, setConfig] = useState<SearchConditionConfig[]>();
  // Mapでも保持しておく
  const [map, setMap] = useState<Map<string, boolean>>();

  useEffect(() => {
    fetchSearchItemConfig();
  }, []);

  const fetchSearchItemConfig = async () => {
    dispatch(
      fetchScreenConfig(
        {
          screen_name: screenName,
          config_type: ConfigType.SEARCH_PANEL,
        },
        (response: SearchConditionConfig[]) => {
          setConfig(response ?? []);
        }
      )
    );
  };

  useEffect(() => {
    if (!config) return;
    const m = new Map<string, boolean>();
    config.forEach((v) => {
      Object.entries(v).forEach(([key, value]) => m.set(key, !!value));
    });
    requiredKeys.forEach((key) => {
      m.set(key, true);
    });
    setMap(m);
  }, [config]);

  const saveSearchItemConfig = async (v: Map<string, boolean>) => {
    const params = [];
    v.forEach((value, key) => params.push({ [key]: value ? 1 : 0 }));
    dispatch(
      updateScreenConfig(
        {
          screen_name: screenName,
          config_type: ConfigType.SEARCH_PANEL,
          config: JSON.stringify(params),
        },
        (response: SearchConditionConfig[]) => {
          setConfig(response);
        }
      )
    );
  };

  const show = (target: string[]): boolean => {
    return showAllConditions || (map && target.some((t) => map.get(t)));
  };

  return {
    /* 隠したい条件の表示/非表示 */
    showAllConditions,
    toggleDisplayOptionalConditions,
    /* 検索条件設定モーダル */
    showSettingModal,
    closeSettingModal,
    openSettingModal,
    /* 検索条件設定 */
    saveSearchItemConfig,
    map,
    show,
  };
};
