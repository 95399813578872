import { connect } from "react-redux";

import actions from "../actions";
import Header from "../components/Header";
import { RootState } from "@/reducers/types";

const mapStateToProps = (state: RootState) => {
  return {
    userName: state.session.userName,
    resultProgressRate: state.construction.resultProgressRate,
    scheduleProgressRate: state.construction.scheduleProgressRate,
    fetchingProgressRate: state.app.fetchingProgressRate,
    notifications: state.notifications,
    fetchedJobsLargestId: state.dataExport.fetchedJobsLargestId,
    construction: state.construction.construction,
    customer: state.construction.customer,
    place: state.construction.place,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.login.logout()),
    showConstructionSelector: () => dispatch(actions.app.showConstructionSelector()),
    showPasswordEditor: () => dispatch(actions.app.showPasswordEditor()),
    reloadProgressRate: () => dispatch(actions.construction.fetchProgressRate()),
    fetchJobs: () => dispatch(actions.dataExport.fetchJobs()),
    showLangSelector: () => dispatch(actions.app.showLangSelector()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
