import React, { FC } from "react";
import clsx from "clsx";

type Props = {
  onClick: () => void;
  className?: string;
};

export const SettingIconButton: FC<Props> = ({ onClick, className }) => {
  return (
    <button className={clsx("flex justify-center items-center opacity-75", className)} onClick={onClick}>
      <img src="./img/icon-setting-black.svg" alt="setting" />
    </button>
  );
};
