import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";
import Select from "react-select";

import styles from "../../constants/reactSelectStyle";
import environments from "../../environments";
import "react-datepicker/dist/react-datepicker.css";
import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";

import moment from "moment";

import * as util from "../../lib/common";
import restoreState from "../../lib/restoreState";
import storageManager from "../../lib/storageManager";
import FileItemImport from "../FileItemImport";
import clsx from "clsx";
import { FooterPager } from "@/components/common/FooterPager";

const autoload = false; // IEの二重リクエスト対策フラグ

class Import extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      activeButton: "",
      showSearch: true,
      showTable: false,
      currentRowIndex: -1,
      downloadUrl: "",
      fetchingItem: false,
      limit: 20,
      start: 1,
      end: false,
      isEmpty: false,
      import_start_date: null,
      import_end_date: null,
      statuses: [
        { status_id: 0, status_name: t("pending") },
        { status_id: 1, status_name: t("regular") },
        { status_id: 2, status_name: t("error_status") },
        { status_id: 3, status_name: t("alert") },
      ],
      filetypes: [
        { type_id: 1, type_name: t("excel") },
        { type_id: 2, type_name: t("ms_project") },
        { type_id: 3, type_name: t("system") },
      ],
      files: [],
      valid_flg: false,
      upload_request_id: 0,
      upload_data: [],
      errorRequestIds: [],
      exportErrFileUrl: "",
      error: {},
      tableBodyMaxHeight: window.innerHeight - 420,
      objectUrl: "",
      downloadFileName: "",
      downloading: false,
      currentPage: 1,
      maxPage: 0,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleFileSelectExcel = this.handleFileSelectExcel.bind(this);
    this.handleFileSelectMsp = this.handleFileSelectMsp.bind(this);
    this.handleFileSelectSystem = this.handleFileSelectSystem.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleChangeFile = this.handleChangeFile.bind(this);
    this.handleUploadProgress = this.handleUploadProgress.bind(this);
    this.handleUploadComplete = this.handleUploadComplete.bind(this);
    this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this);
    this.handleChangeDateTo = this.handleChangeDateTo.bind(this);
    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.fillList = this.fillList.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleDownloadErrors = this.handleDownloadErrors.bind(this);
    this.handleDownloadFormats = this.handleDownloadFormats.bind(this);
  }

  componentDidMount() {
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const urlState = restoreState();
    const { validated } = this.props;
    if (
      validated &&
      (storageManager.getConstructionItem("importSearchParams") !== null || (urlState && urlState.hasQuery))
    ) {
      this.handleSearch();
    }

    this.handleSearchBoxHeightChange();
    addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
  }

  componentDidUpdate(prevProps) {
    const { validated } = this.props;
    if (!validated) {
      return;
    }

    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("importSearchParams") !== null || (urlState && urlState.hasQuery);
    if (!prevProps.validated && validated && notFirst) {
      this.handleSearch();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox);

    this.props.revertLocalCondition();
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("import", this.searchBox, this.theader);
    this.setState({ tableBodyMaxHeight: maxHeight });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
      this.fillList();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
      this.fillList();
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  showDropdown(name) {
    let endpoint = `${environments.API_BASE_URI}/import/errors`;

    if (name === "batch") {
      let url = "";

      if (this.state.errorRequestIds) {
        this.state.errorRequestIds.forEach((val) => {
          url = url + "request_ids=" + val + "&";
        });
        endpoint = `${endpoint}?${url.slice(0, -1)}`;
      }
    }
    this.setState({
      ...this.state,
      activeButton: name,
      exportErrFileUrl: endpoint,
    });
  }

  hideDropdown() {
    this.setState({ ...this.state, activeButton: "" });
  }

  showTable(callback) {
    this.setState({ showTable: true }, () => {
      if (callback !== undefined) {
        setTimeout(callback, 100);
      }
    });
  }

  toggleCheckobx(request_id) {
    if (this.state.errorRequestIds.indexOf(request_id) >= 0) {
      this.setState({
        errorRequestIds: this.state.errorRequestIds.filter((item) => item != request_id),
      });
    } else {
      this.setState({
        errorRequestIds: [...this.state.errorRequestIds, request_id],
      });
    }
  }

  fillList() {
    const { showTable, end } = this.state;

    if (!showTable || end) {
      return;
    }
  }

  handleSearch() {
    this.setState({
      isEmpty: true,
      fetching: true,
      errorRequestIds: [],
    });

    const error = {};
    const { categoryId, fileName, statusId, import_start_date, import_end_date, userId, fileType, search, t } =
      this.props;

    const params = {
      categoryId,
      fileName,
      statusId,
      import_start_date,
      import_end_date,
      userId,
      fileType,
      start: 1,
      limit: this.state.limit,
    };

    // 日付のチェック
    if (import_start_date !== null && import_end_date !== null) {
      const sd = moment(import_start_date);
      const ed = moment(import_end_date);
      error.import_start_date = ed.diff(sd, "days") < 0 ? t("schedule_alert") : "";
      this.setState({ error });
    }

    search(params, (data) => {
      this.setState({
        start: data.length + 1,
        end: data.length < this.state.limit,
        isEmpty: data.length === 0,
        fetchingItem: false,
        files: [],
        maxPage: Math.ceil(data.total_num / this.state.limit),
        currentPage: Math.ceil(params.start / this.state.limit),
      });

      this.showTable(this.fillList);
    });
  }

  handleSearchPager(startPos = 1) {
    this.setState({
      isEmpty: true,
      fetching: true,
      errorRequestIds: [],
    });

    const error = {};
    const { categoryId, fileName, statusId, import_start_date, import_end_date, userId, fileType, search, t } =
      this.props;

    const params = {
      categoryId,
      fileName,
      statusId,
      import_start_date,
      import_end_date,
      userId,
      fileType,
      start: startPos,
      limit: this.state.limit,
    };

    // 日付のチェック
    if (import_start_date !== null && import_end_date !== null) {
      const sd = moment(import_start_date);
      const ed = moment(import_end_date);
      error.import_start_date = ed.diff(sd, "days") < 0 ? t("schedule_alert") : "";
      this.setState({ error });
    }

    search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
          fetchingItem: false,
          files: [],
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
          totalNum: data.total_num,
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleNextPage() {
    const { currentPage, limit, maxPage } = this.state;
    if (currentPage < maxPage) {
      const startPos = 1 + currentPage * limit;
      this.handleSearchPager(startPos);
    }
  }

  handlePrevPage() {
    const { currentPage, limit } = this.state;
    if (currentPage > 1) {
      const startPos = 1 + (currentPage - 2) * limit;
      this.handleSearchPager(startPos);
    }
  }

  handleJumpPage(page) {
    const { limit } = this.state;
    const startPos = 1 + (page - 1) * limit;
    this.handleSearchPager(startPos);
  }

  handleUploadProgress(key, e) {
    this.setState({
      files: this.state.files.map((file) => {
        if (file.temp && file.key === key) {
          file.progress = (e.loaded / e.total) * 100;
        }

        return file;
      }),
    });
  }

  handleUploadComplete(key, e) {
    this.setState({
      files: this.state.files.map((file) => {
        if (file.temp && file.key === key) {
          file.progress = 100;
          file.temp_file_id = e.temp_file_id;
        }

        return file;
      }),
    });
  }

  handleChangeFile(e) {
    let files = [];
    const { impFileType } = this.props;

    Array.prototype.forEach.call(e.target.files, (file) => {
      files = [
        ...files,
        {
          key: Symbol(),
          file_name: file.name,
          file_format: file.type,
          temp: true,
          progress: 0,
          done: false,
          data: file,
        },
      ];
    });

    files.forEach((file) => {
      const formData = new FormData();
      formData.append("filetype", impFileType);
      formData.append("uploadFile", file.data);
      this.props.uploadFile(
        formData,
        (e) => this.handleUploadProgress(file.key, e),
        (e) => this.handleUploadComplete(file.key, e),
        (data, isError) => {
          this.setState({
            files: this.state.files.map((f) => {
              if (f.key === file.key) {
                f.upload_data = isError ? null : data;
                f.error = isError;
                f.errmsg = isError ? data : "";

                return f;
              }

              return f;
            }),
          });
        }
      );
    });
    this.setState({
      files: [...files, ...this.state.files],
      showTable: true,
    });
  }

  handleReset() {
    this.setState({
      files: [],
      error: "",
      fetchingItem: true,
      errorRequestIds: [],
    });

    const { search } = this.props;

    const params = {
      categoryId: 0,
      fileName: "",
      statusId: -1,
      import_start_date: "",
      import_end_date: "",
      userId: 0,
      fileType: 0,
      start: 1,
      limit: this.state.limit,
    };

    search(params, (data) => {
      this.setState({
        start: data.length + 1,
        end: data.length < this.state.limit,
        isEmpty: data.length === 0,
        fetchingItem: false,
        maxPage: Math.ceil(data.total_num / this.state.limit),
        currentPage: Math.ceil(params.start / this.state.limit),
      });

      this.showTable(this.fillList);
    });
  }

  handleFileSelectExcel() {
    this.props.importFiletype(1);
    setTimeout(() => this.file.click(), 10);
  }

  handleFileSelectMsp() {
    this.props.importFiletype(2);
    setTimeout(() => this.file.click(), 10);
  }

  handleFileSelectSystem() {
    this.props.importFiletype(3);
    setTimeout(() => this.file.click(), 10);
  }

  handleFileSelect() {
    this.file.click();
  }

  handleChangeDateFrom(date) {
    this.setState({ import_start_date: date });
    this.props.changeDateFrom(
      date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : ""
    );
  }

  handleChangeDateTo(date) {
    this.setState({ import_end_date: date });
    this.props.changeDateTo(
      date
        ? date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
        : ""
    );
  }

  handleDownload(request_id) {
    const { download } = this.props;

    download("import", request_id, false, (blob, filename) => {
      const objectUrl = URL.createObjectURL(blob);
      this.setState({ objectUrl, downloadFileName: filename }, () => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const evt = util.createClickEvent();
          this.btnDownload.dispatchEvent(evt);
        }
      });
    });
  }

  handleDownloadErrors(request_ids) {
    const { downloadErrors, toggleDownloading } = this.props;
    toggleDownloading(true);

    const fallback = () => toggleDownloading(false);

    downloadErrors(
      "import",
      request_ids,
      (blob, filename) => {
        const objectUrl = URL.createObjectURL(blob);
        this.setState({ objectUrl, downloadFileName: filename }, () => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            const evt = util.createClickEvent();
            this.btnDownload.dispatchEvent(evt);
          }

          toggleDownloading(false);
        });
      },
      fallback
    );
  }

  handleDownloadFormats(filetype) {
    this.props.downloadFormats(filetype, (blob, filename) => {
      const objectUrl = URL.createObjectURL(blob);
      this.setState({ objectUrl, downloadFileName: filename }, () => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          const evt = util.createClickEvent();
          this.btnDownload.dispatchEvent(evt);
        }
      });
    });
  }

  render() {
    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;
    const {
      categoryId,
      fileName,
      statusId,
      import_start_date,
      import_end_date,
      userId,
      fileType,
      items,
      fetching,
      categories,
      primaryCharges,
      users,
      changeCategory,
      changeFilename,
      changeUser,
      changeStatus,
      changeFiletype,
      resetSearch,
      t,
      isError,
      downloading,
    } = this.props;
    const isInvalid = this.state.valid_flg === false;
    const error = this.state.error;

    return (
      <div className="contents dataimport">
        <div className="inner">
          <div className="tbl-top-area mt-15 clearfix">
            <div className="tbl-top-left">
              <h1 className="page-ttl">
                {t("data_import")}
                <span
                  data-test-id="button-import-toggle-search"
                  className={titleClassName}
                  onClick={() => this.toggleSearch()}
                ></span>
              </h1>
              <div className="btn-dropdown-area top ml-20" onMouseLeave={() => this.hideDropdown()}>
                <button
                  data-test-id="button-import-file-import"
                  className="btn btn-light-blue btn-dropdown"
                  onClick={() => this.showDropdown("import")}
                >
                  <span className="icon icon-file_upload"></span>
                  {t("file_import")}
                </button>
                <form ref={(node) => (this.form = node)} style={{ display: "none" }} encType={"multipart/form-data"}>
                  <input
                    type="file"
                    name="uploadFile"
                    accept=".xlsx, .xls, .xlsm, .xlm"
                    multiple={true}
                    onChange={this.handleChangeFile}
                    onClick={(e) => (e.target.value = null)}
                    ref={(node) => (this.file = node)}
                  />
                </form>
                {this.state.activeButton === "import" && (
                  <ul className="dropdown-menu">
                    <li data-test-id="button-import-file-import-excel" onClick={this.handleFileSelectExcel}>
                      <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                      {t("excel")}
                    </li>
                    <li data-test-id="button-import-file-import-msp" onClick={this.handleFileSelectMsp}>
                      <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                      {t("ms_project")}
                    </li>
                    <li data-test-id="button-import-file-import-system" onClick={this.handleFileSelectSystem}>
                      <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                      {t("system")}
                    </li>
                  </ul>
                )}
              </div>
              <div className="btn-dropdown-area top" onMouseLeave={() => this.hideDropdown()}>
                <button
                  data-test-id="button-import-download"
                  className="btn btn-light-blue btn-dropdown"
                  onClick={() => this.showDropdown("export")}
                >
                  <span className="icon icon-get_app"></span>
                  {t("download")}
                </button>
                {this.state.activeButton === "export" && (
                  <ul className="dropdown-menu dl" onClick={() => this.hideDropdown()}>
                    <li
                      data-test-id="button-import-download-excel"
                      disabled={false}
                      onClick={() => this.handleDownloadFormats(1)}
                    >
                      <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                      {t("excel_blank_form")}
                    </li>
                    <li
                      data-test-id="button-import-download-convert"
                      disabled={false}
                      onClick={() => this.handleDownloadFormats(2)}
                    >
                      <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                      {t("conversion_tool")}
                    </li>
                    <li
                      data-test-id="button-import-download-system"
                      disabled={false}
                      onClick={() => this.handleDownloadFormats(3)}
                    >
                      <img src="./img/icon_xls.svg" alt="XLS" className="icon-file" />
                      {t("system")}
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
          {this.state.showSearch && (
            <div className="search-box" ref={(node) => (this.searchBox = node)}>
              <div className="form-row">
                <div className="form-group w-340">
                  <span className="form-label">{t("machines_category")}</span>
                  <div className="w-270 d-ib ta-l">
                    <Select
                      styles={styles}
                      isMulti={false}
                      isClearable={true}
                      options={categories.map((category) => ({
                        value: category.category_id,
                        label: category.category_name,
                      }))}
                      onChange={(e) => changeCategory(e)}
                      value={categories
                        .filter((category) => category.category_id === categoryId)
                        .map((category) => ({ value: category.category_id, label: category.category_name }))}
                    />
                  </div>
                </div>
                <div className="form-group w-330">
                  <span className="form-label">{t("file_name")}</span>
                  <input
                    data-test-id="text-import-file-name"
                    type="text"
                    className="form-control w-230"
                    value={fileName}
                    onChange={(e) => changeFilename(e.target.value)}
                  />
                </div>
                <div className="form-group w-260">
                  <span className="form-label">{t("status")}</span>
                  <select
                    data-test-id="text-import-status"
                    className="form-control w-170"
                    value={statusId}
                    onChange={(e) => changeStatus(Number(e.target.value))}
                  >
                    <option key={-1} value={-1}>
                      -----
                    </option>
                    {this.state.statuses.map((status) => (
                      <option key={status.status_id} value={status.status_id}>
                        {status.status_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-row mb-0">
                <div className="form-group w-340 import-search">
                  <span className="form-label">{t("imported_at")}</span>
                  <div className="d-ib">
                    <DatePicker
                      wrapperClassName="w-120"
                      disabled={false}
                      selected={import_start_date ? moment(import_start_date).toDate() : null}
                      dateFormat="yyyy/MM/dd"
                      locale={t("calender_locale")}
                      onChange={(date) => this.handleChangeDateFrom(date)}
                    />
                  </div>
                  <div className="d-ib w-30 ta-c">&nbsp;〜&nbsp;</div>
                  <div className="d-ib">
                    <DatePicker
                      wrapperClassName="w-120"
                      disabled={false}
                      selected={import_end_date ? moment(import_end_date).toDate() : null}
                      dateFormat="yyyy/MM/dd"
                      locale={t("calender_locale")}
                      onChange={(date) => this.handleChangeDateTo(date)}
                    />
                  </div>
                  {error.import_start_date && (
                    <div className="form-error w-380">
                      <p className="form-error-message w-300">{error.import_start_date}</p>
                    </div>
                  )}
                </div>
                <div className="form-group w-330">
                  <span className="form-label">{t("imported_by")}</span>
                  <div className="w-230 d-ib ta-l">
                    <Select
                      styles={styles}
                      isMulti={false}
                      isClearable={true}
                      options={users.map((user) => ({ value: user.user_id, label: user.user_name }))}
                      onChange={(e) => changeUser(e)}
                      value={users
                        .filter((user) => user.user_id === userId)
                        .map((user) => ({ value: user.user_id, label: user.user_name }))}
                    />
                  </div>
                </div>
                <div className="form-group w-260">
                  <span className="form-label">{t("imported_class")}</span>
                  <select
                    data-test-id="text-import-file-type"
                    className="form-control w-170"
                    value={fileType}
                    onChange={(e) => changeFiletype(Number(e.target.value))}
                  >
                    <option key={0} value={0}>
                      -----
                    </option>
                    {this.state.filetypes.map((filetype) => (
                      <option key={filetype.type_id} value={filetype.type_id}>
                        {filetype.type_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group btn-area pull-right">
                  <button
                    data-test-id="button-import-reset"
                    className="btn btn-gray"
                    onClick={() => {
                      resetSearch();
                      this.handleReset();
                    }}
                  >
                    {t("reset")}
                  </button>
                  <button
                    data-test-id="button-import-search"
                    className="btn btn-blue"
                    onClick={() => this.handleSearch()}
                  >
                    {t("search")}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className={fetching ? "loading loading--list" : ""}>
            {this.state.isEmpty || !items || !items.length
              ? this.state.showTable &&
                !isError &&
                this.state.files.length === 0 &&
                !fetching && <p className="empty-message">{t("no_data")}</p>
              : this.state.showTable &&
                !isError && (
                  <React.Fragment>
                    <div className="tbl-top-area clearfix relative">
                      <div className="tbl-top-left">
                        <div className="btn-dropdown-area" onMouseLeave={() => this.hideDropdown()}>
                          <button
                            data-test-id="button-import-batch"
                            className="btn btn-blue btn-dropdown"
                            onClick={() => this.showDropdown("batch")}
                            disabled={this.state.errorRequestIds.length > 0 ? false : true}
                          >
                            <span
                              className={clsx(
                                "icon",
                                this.state.errorRequestIds.length > 0 ? "icon-check_box" : "icon-check_box_disabled"
                              )}
                            />
                            {t("batch")}
                          </button>
                          {this.state.activeButton === "batch" && (
                            <ul className="dropdown-menu" onClick={() => this.hideDropdown()}>
                              <li
                                data-test-id="button-import-error-export"
                                disabled={false}
                                onClick={() => this.handleDownloadErrors(this.state.errorRequestIds)}
                              >
                                {t("export_error")}
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className={`${downloading ? "loading-small loading-small-download" : ""}`} />
                    </div>
                    <div className={`tbl-category ${this.state.fetchingItem ? "loading loading--list" : ""}`}>
                      <table className="tbl-basic tbl-data">
                        <thead className="list-head tbl-head-adjusted" ref={(node) => (this.theader = node)}>
                          <tr>
                            <th></th>
                            <th>{t("file_name")}</th>
                            <th>{t("machines_category")}</th>
                            <th>{t("primary_charge")}</th>
                            <th>{t("imported_class")}</th>
                            <th>{t("imported_at")}</th>
                            <th>{t("imported_by")}</th>
                            <th>{t("status")}</th>
                            <th>{t("error")}</th>
                          </tr>
                        </thead>
                        <tbody
                          className="list-body"
                          style={{ maxHeight: this.state.tableBodyMaxHeight }}
                          ref={(node) => (this.tbody = node)}
                        >
                          {this.state.files.map((file, index) => (
                            <FileItemImport
                              disabled={isInvalid}
                              key={index}
                              file={file}
                              upload_data={file.upload_data ? file.upload_data : ""}
                              onChange={() => this.toggleCheckobx(file.upload_data ? file.upload_data.request_id : "")}
                              categories={categories}
                              primaryCharges={primaryCharges}
                              filetypes={this.state.filetypes}
                              statuses={this.state.statuses}
                              checked_flg={
                                this.state.errorRequestIds.indexOf(
                                  file.upload_data ? file.upload_data.request_id : ""
                                ) > -1
                              }
                              hrefFile={`${environments.API_BASE_URI}/import/files` + "/"}
                              hrefError={`${environments.API_BASE_URI}/import/errors` + "?request_ids="}
                            />
                          ))}
                          {items.map((item, index) => {
                            const status = this.state.statuses.filter((s) => s.status_id === item.status)[0];
                            const types = this.state.filetypes.filter((t) => t.type_id === item.file_type)[0];
                            const importDate = new Date(item.import_date);
                            const importDateConv =
                              importDate.getFullYear() +
                              "/" +
                              ("0" + (importDate.getMonth() + 1)).slice(-2) +
                              "/" +
                              ("0" + importDate.getDate()).slice(-2) +
                              " " +
                              ("0" + importDate.getHours()).slice(-2) +
                              ":" +
                              ("0" + importDate.getMinutes()).slice(-2) +
                              ":" +
                              ("0" + importDate.getSeconds()).slice(-2);
                            const className_chk = item.status !== 2 && item.status !== 3 ? "ckbox disp-non" : "ckbox";
                            const className = item.status === 2 ? "txt-center cell-orange" : "txt-center";
                            const request_id_arr = [item.request_id];

                            return (
                              <tr key={index}>
                                <td className="txt-center">
                                  <label className={className_chk}>
                                    <input type="checkbox" onClick={() => this.toggleCheckobx(item.request_id)} />
                                    <span></span>
                                  </label>
                                </td>
                                <td>
                                  <a disabled={false} onClick={() => this.handleDownload(item.request_id)}>
                                    {item.file_name}
                                  </a>
                                </td>
                                <td>{item.category_name}</td>
                                <td>{item.primary_charge_name}</td>
                                <td>{types.type_name}</td>
                                <td className="txt-center">{importDateConv}</td>
                                <td>{item.import_user_name}</td>
                                <td className={className}>{status.status_name}</td>
                                <td className="txt-center">
                                  <a disabled={false} onClick={() => this.handleDownloadErrors(request_id_arr)}>
                                    {item.status === 2 || item.status === 3 ? t("export") : ""}
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </React.Fragment>
                )}
          </div>
          <a
            className="d-n"
            download={this.state.downloadFileName}
            href={this.state.objectUrl}
            ref={(node) => (this.btnDownload = node)}
          >
            download
          </a>
        </div>
        {this.state.maxPage > 0 && (
          <FooterPager
            currentPage={this.state.currentPage}
            maxPage={this.state.maxPage}
            onPrev={() => this.handlePrevPage()}
            onNext={() => this.handleNextPage()}
            onJump={(page) => this.handleJumpPage(page)}
          />
        )}
      </div>
    );
  }
}

Import.propTypes = {
  changeUser: PropTypes.func.isRequired,
  changeCategory: PropTypes.func.isRequired,
  changeCompany: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isError: PropTypes.bool.isRequired,
  validated: PropTypes.bool.isRequired,
  revertLocalCondition: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired,
  toggleDownloading: PropTypes.func.isRequired,
};

export default withTranslation()(Import);
