/* 表示設定 */
export const ScreenName = {
  MATRIX: "matrix",
} as const;

export type ScreenNameType = (typeof ScreenName)[keyof typeof ScreenName];

export const ConfigType = {
  SEARCH_PANEL: 1,
  COLUMN: 2,
} as const;

export type ConfigTypeType = (typeof ConfigType)[keyof typeof ConfigType];

/* 表示設定 > 一覧検索条件設定 */
export type SearchConditionConfig = { [key: string]: 1 | 0 };

export type SearchConditionConfigDefinition<T> = {
  itemsPerLine: number;
  items: {
    key: T;
    label: string;
    required?: boolean;
  }[];
};
