import PropTypes from "prop-types";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { withTranslation } from "react-i18next";

import TaskEditorContainer from "../../containers/list/TaskEditorContainer";
import { addHeightResizeListener, removeHeightResizeListener, getTableBodyHeight } from "../../lib/common";
import restoreState from "../../lib/restoreState";
import storageManager from "../../lib/storageManager";
import Select from "../common/Select";
import StatusSwitcher from "../common/StatusSwitcher";

import ApprovalGridBody from "./ApprovalGridBody";
import ApprovalGridHeader from "./ApprovalGridHeader";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { TaskSwitcherStatus } from "@/models/tasks";
import { FooterPager } from "@/components/common/FooterPager";

class Approval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearch: true,
      showTable: false,
      showTaskEditor: false,
      currentRowIndex: -1,
      limit: 20,
      start: 1,
      end: false,
      isEmpty: false,
      editingItem: null,
      tableBodyMaxHeight: window.innerHeight - 420,
      linkageInfo: null,
      currentPage: 1,
      maxPage: 0,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleSearchBoxHeightChange = this.handleSearchBoxHeightChange.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.fillList = this.fillList.bind(this);
  }

  componentDidMount() {
    this.props.changeSwitcherStatus(-1);
    this.resizeTimer = 0;
    window.addEventListener("resize", this.handleResize);

    const urlState = restoreState();
    const { validated } = this.props;
    if (
      validated &&
      (storageManager.getConstructionItem("approvalSearchParams") !== null || (urlState && urlState.hasQuery))
    ) {
      this.handleSearch();
    }

    this.handleSearchBoxHeightChange();
    addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
  }

  componentDidUpdate(prevProps) {
    const { validated } = this.props;
    if (!validated) {
      return;
    }
    const urlState = restoreState();
    const notFirst =
      storageManager.getConstructionItem("approvalSearchParams") !== null || (urlState && urlState.hasQuery);
    if (!prevProps.validated && validated && notFirst) {
      this.handleSearch();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    removeHeightResizeListener(this.searchBox);

    this.props.revertLocalCondition();
  }

  handleSearchBoxHeightChange() {
    const maxHeight = getTableBodyHeight("approval", this.searchBox, this.theader);
    this.setState({ tableBodyMaxHeight: maxHeight });
  }

  handleResize() {
    if (this.resizeTimer > 0) {
      clearTimeout(this.resizeTimer);
    }

    const callback = () => {
      this.handleSearchBoxHeightChange();
      this.fillList();
    };

    this.resizeTimer = setTimeout(callback, 200);
  }

  toggleSearch() {
    const callback = () => {
      this.handleSearchBoxHeightChange();
      addHeightResizeListener(this.searchBox, this.handleSearchBoxHeightChange);
      this.fillList();
    };
    this.setState({ showSearch: !this.state.showSearch }, () => setTimeout(callback, 100));
  }

  showTable(callback) {
    this.setState({ showTable: true }, () => {
      if (callback !== undefined) {
        // コールバックにしてもsetState直後はDOMに反映していない
        // ことがあるため、時間差で実行する
        setTimeout(callback, 100);
      }
    });
  }

  showTaskEditor(item, linkageInfo) {
    this.setState({
      editingItem: item,
      showTaskEditor: true,
      linkageInfo: linkageInfo,
    });
  }

  hideTaskEditor() {
    this.setState({ showTaskEditor: false });
  }

  fillList() {
    const { showTable, end } = this.state;

    if (!showTable || end) {
      return;
    }
  }

  handleSearch() {
    this.setState({ isEmpty: true });

    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      processMiddleClassIds,
      companyIds,
      scheduleEndDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      search,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      processMiddleClassIds,
      companyIds,
      scheduleEndDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      start: 1,
      limit: this.state.limit,
    };

    search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleSearchPager(startPos = 1) {
    this.setState({ isEmpty: true });

    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      processMiddleClassIds,
      companyIds,
      scheduleEndDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      search,
    } = this.props;

    const params = {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      processMiddleClassIds,
      companyIds,
      scheduleEndDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      start: startPos,
      limit: this.state.limit,
    };

    search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  handleNextPage() {
    const { currentPage, limit, maxPage } = this.state;
    if (currentPage < maxPage) {
      const startPos = 1 + currentPage * limit;
      this.handleSearchPager(startPos);
    }
  }

  handlePrevPage() {
    const { currentPage, limit } = this.state;
    if (currentPage > 1) {
      const startPos = 1 + (currentPage - 2) * limit;
      this.handleSearchPager(startPos);
    }
  }

  handleJumpPage(page) {
    const startPos = 1 + (page - 1) * this.state.limit;
    this.handleSearchPager(startPos);
  }

  handleClear() {
    this.props.clearSearch();
    this.setState({ start: 1, showTable: false });

    const params = {
      areaIds: [],
      deviceIds: [],
      categoryId: 0,
      facilityManagementIds: [],
      constructionManagementIds: [],
      primaryChargeIds: [],
      otherIds: [],
      processMajorClassIds: [],
      processMiddleClassIds: [],
      companyIds: [],
      scheduleEndDate: "",
      checkpointIds: [],
      itemText: "",
      processText: "",
      filter: [],
      start: 1,
      limit: this.state.limit,
    };

    this.props.search(params, (data) => {
      this.setState(
        {
          start: data.length + 1,
          end: data.length < this.state.limit,
          isEmpty: data.length === 0,
          maxPage: Math.ceil(data.total_num / this.state.limit),
          currentPage: Math.ceil(params.start / this.state.limit),
        },
        () => {
          this.showTable(this.fillList);
        }
      );
    });
  }

  render() {
    const titleClassName = `toggle icon-keyboard_arrow_up ${!this.state.showSearch ? "closed" : ""}`;
    const {
      masters,
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      companyIds,
      processMajorClassIds,
      processMiddleClassIds,
      scheduleEndDate,
      checkpointIds,
      itemText,
      processText,
      filter,
      changeArea,
      changeDevice,
      changeFacilityManagement,
      changeCategory,
      changeConstructionManagement,
      changePrimaryCharge,
      changeOther,
      changeProcessMajorClass,
      changeProcessMiddleClass,
      changeCompany,
      changeScheduleEndDate,
      changeCheckpoint,
      changeItemText,
      changeProcessText,
      changeFilter,
      t,
      items,
      fetching,
      linkageTasks,
    } = this.props;

    const { currentPage, limit } = this.state;

    return (
      <div>
        <div className="contents">
          <div className="inner">
            <h1 className="page-ttl">
              {t("plan_approval_list")}
              <span
                data-test-id="button-approval-toggle-search"
                className={titleClassName}
                onClick={() => this.toggleSearch()}
              ></span>
            </h1>
            {this.state.showSearch && (
              <div className="search-box" ref={(node) => (this.searchBox = node)}>
                <div className="form-row">
                  <div className="form-group w-250">
                    <span className="form-label">{t("area")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        prefix="area"
                        isMulti={true}
                        options={masters.areas}
                        value={areaIds}
                        onChange={changeArea}
                      />
                    </div>
                  </div>
                  <div className="form-group w-300">
                    <span className="form-label">{t("device")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        prefix="device"
                        isMulti={true}
                        options={masters.devices}
                        value={deviceIds}
                        onChange={changeDevice}
                      />
                    </div>
                  </div>
                  <div className="form-group w-300">
                    <span className="form-label">{t("facility_management")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        prefix="facility_management"
                        isMulti={true}
                        options={masters.facilityManagements}
                        value={facilityManagementIds}
                        onChange={changeFacilityManagement}
                      />
                    </div>
                  </div>
                  <div className="form-group w-310">
                    <span className="form-label">{t("machines_category")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        prefix="category"
                        isMulti={false}
                        options={masters.categories}
                        value={categoryId}
                        onChange={changeCategory}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group w-250">
                    <span className="form-label">{t("construction_management")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        prefix="construction_management"
                        isMulti={true}
                        options={masters.constructionManagements}
                        value={constructionManagementIds}
                        onChange={changeConstructionManagement}
                      />
                    </div>
                  </div>
                  <div className="form-group w-300">
                    <span className="form-label">{t("primary_charge")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        prefix="primary_charge"
                        isMulti={true}
                        options={masters.primaryCharges}
                        value={primaryChargeIds}
                        onChange={changePrimaryCharge}
                      />
                    </div>
                  </div>
                  <div className="form-group w-300">
                    <span className="form-label">{t("other")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        prefix="other"
                        isMulti={true}
                        options={masters.others}
                        value={otherIds}
                        onChange={changeOther}
                      />
                    </div>
                  </div>
                  <div className="form-group w-310">
                    <span className="form-label">{t("process_major_class")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        prefix="process_major_class"
                        isMulti={true}
                        options={masters.processMajorClasses}
                        value={processMajorClassIds}
                        onChange={changeProcessMajorClass}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group w-250">
                    <span className="form-label">{t("company")}</span>
                    <div className="d-ib ta-l w-170">
                      <Select
                        prefix="company"
                        options={masters.companies}
                        value={companyIds}
                        onChange={changeCompany}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  <div className="form-group w-300">
                    <span className="form-label">{t("process_middle_class")}</span>
                    <div className="d-ib ta-l w-170">
                      <Select
                        prefix="process_middle_class"
                        options={masters.process_middle_classes}
                        value={processMiddleClassIds}
                        onChange={changeProcessMiddleClass}
                        isMulti={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group w-250 scheduled-date">
                    <span className="form-label">{t("scheduled_date")}</span>
                    <DatePicker
                      wrapperClassName="w-170"
                      selected={scheduleEndDate === "" ? null : moment(scheduleEndDate, "YYYY/MM/DD").toDate()}
                      dateFormat="yyyy/MM/dd"
                      locale={t("calender_locale")}
                      onChange={(date) =>
                        changeScheduleEndDate(date === null ? "" : moment(date).format("YYYY/MM/DD").toString())
                      }
                    />
                  </div>
                  <div className="form-group w-300">
                    <span className="form-label">{t("approval_confirm")}</span>
                    <div className="w-170 d-ib ta-l">
                      <Select
                        prefix="checkpoint"
                        isMulti={true}
                        options={masters.checkpoints}
                        value={checkpointIds}
                        onChange={changeCheckpoint}
                      />
                    </div>
                  </div>
                  <div className="form-group w-300">
                    <span className="form-label">{t("title")}</span>
                    <input
                      data-test-id="text-approval-item-name"
                      type="text"
                      className="form-control w-170"
                      value={itemText}
                      onChange={(e) => changeItemText(e.target.value)}
                    />
                  </div>
                  <div className="form-group w-310">
                    <span className="form-label">{t("process_title")}</span>
                    <input
                      data-test-id="text-approval-process-name"
                      type="text"
                      className="form-control w-170"
                      value={processText}
                      onChange={(e) => changeProcessText(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-row mb-0">
                  <div className="form-group mt-10 ml-10">
                    <label data-test-id="checkbox-approval-not-started" className="ckbox">
                      <input type="checkbox" checked={_.includes(filter, 1)} onChange={() => changeFilter(1)} />
                      <span>{t("not_started")}</span>
                    </label>
                    <label data-test-id="checkbox-approval-scheduled-construction" className="ckbox">
                      <input type="checkbox" checked={_.includes(filter, 2)} onChange={() => changeFilter(2)} />
                      <span>{t("coming")}</span>
                    </label>
                    <label data-test-id="checkbox-approval-over-limit" className="ckbox">
                      <input type="checkbox" checked={_.includes(filter, 3)} onChange={() => changeFilter(3)} />
                      <span>{t("expired")}</span>
                    </label>
                    <label data-test-id="checkbox-approval-completed" className="ckbox">
                      <input type="checkbox" checked={_.includes(filter, 4)} onChange={() => changeFilter(4)} />
                      <span>{t("completed")}</span>
                    </label>
                    <label data-test-id="checkbox-approval-prework-completion-report" className="ckbox">
                      <input type="checkbox" checked={_.includes(filter, 5)} onChange={() => changeFilter(5)} />
                      <span>{t("prework_completion_report")}</span>
                    </label>
                  </div>
                  <div className="form-group btn-area pull-right mt-0">
                    <button data-test-id="button-approval-reset" className="btn btn-gray" onClick={this.handleClear}>
                      {t("reset")}
                    </button>
                    <button data-test-id="button-approval-search" className="btn btn-blue" onClick={this.handleSearch}>
                      {t("search")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {this.state.showTable && !this.state.isEmpty && !!items && !!items.length && (
              <div className="tbl-top-area clearfix">
                <div className="tbl-top-left">
                  <StatusSwitcher
                    value={this.props.switcherStatus}
                    onChange={(value) => this.props.changeSwitcherStatus(value)}
                  />
                </div>
              </div>
            )}
            <div className={`tbl-approvals ${fetching ? "loading loading--list" : ""}`}>
              {this.state.isEmpty || !items || !items.length
                ? this.state.showTable && !fetching && <p className="empty-message">{t("no_data")}</p>
                : this.state.showTable && (
                    <React.Fragment>
                      <div
                        className="tbl-area tbl-approvals-header tbl-head-adjusted"
                        ref={(node) => (this.theader = node)}
                      >
                        <ApprovalGridHeader userRole={masters.userRole} />
                      </div>
                      <div
                        className={`tbl-area tbl-approvals-body ${!this.state.showSearch ? "is-large" : ""} ${
                          this.props.switcherStatus === TaskSwitcherStatus.OFF
                            ? ""
                            : `cursor-${this.props.switcherStatus}`
                        }`}
                        ref={(node) => (this.tbody = node)}
                        style={{ maxHeight: this.state.tableBodyMaxHeight }}
                      >
                        <ApprovalGridBody
                          no={currentPage * limit - limit + 1}
                          rows={items}
                          switcherStatus={this.props.switcherStatus}
                          showTaskEditor={(item, linkageInfo) => this.showTaskEditor(item, linkageInfo)}
                          linkageTasks={linkageTasks}
                        />
                      </div>
                    </React.Fragment>
                  )}
            </div>
          </div>
        </div>
        {this.state.maxPage > 0 && (
          <FooterPager
            currentPage={this.state.currentPage}
            maxPage={this.state.maxPage}
            onPrev={() => this.handlePrevPage()}
            onNext={() => this.handleNextPage()}
            onJump={(page) => this.handleJumpPage(page)}
          />
        )}
        {this.state.showTaskEditor && (
          <TaskEditorContainer
            taskId={this.state.editingItem.task_id}
            itemName={this.state.editingItem.item_name}
            processName={this.state.editingItem.process_name}
            categoryId={this.state.editingItem.category_id}
            closeHandler={() => this.hideTaskEditor()}
            saveHandler={() => this.hideTaskEditor()}
            linkageInfo={this.state.linkageInfo}
          />
        )}
      </div>
    );
  }
}

Approval.propTypes = {
  areaIds: PropTypes.array.isRequired,
  deviceIds: PropTypes.array.isRequired,
  categoryId: PropTypes.number.isRequired,
  facilityManagementIds: PropTypes.array.isRequired,
  constructionManagementIds: PropTypes.array.isRequired,
  primaryChargeIds: PropTypes.array.isRequired,
  otherIds: PropTypes.array.isRequired,
  processMajorClassIds: PropTypes.array.isRequired,
  scheduleEndDate: PropTypes.string.isRequired,
  checkpointIds: PropTypes.array.isRequired,
  itemText: PropTypes.string.isRequired,
  processText: PropTypes.string.isRequired,
  filter: PropTypes.array.isRequired,
  masters: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  changeArea: PropTypes.func.isRequired,
  changeDevice: PropTypes.func.isRequired,
  changeFacilityManagement: PropTypes.func.isRequired,
  changeCategory: PropTypes.func.isRequired,
  changeConstructionManagement: PropTypes.func.isRequired,
  changePrimaryCharge: PropTypes.func.isRequired,
  changeOther: PropTypes.func.isRequired,
  changeProcessMajorClass: PropTypes.func.isRequired,
  changeScheduleEndDate: PropTypes.func.isRequired,
  changeCheckpoint: PropTypes.func.isRequired,
  changeItemText: PropTypes.func.isRequired,
  changeProcessText: PropTypes.func.isRequired,
  changeFilter: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  changeSwitcherStatus: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  revertLocalCondition: PropTypes.func.isRequired,
  validated: PropTypes.bool.isRequired,
};

export default withTranslation()(Approval);
