import clsx from "clsx";
import moment from "moment/moment";
import React, { FC, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import actions from "@/actions";
import { FooterPager } from "@/components/common/FooterPager";
import { GridCell } from "@/components/list/Schedule/components/GridCell";
import { SCHEDULE_DATA_PER_PAGE } from "@/components/list/Schedule/constants";
import { ScheduleResult } from "@/components/list/Schedule/types";
import { getConstructionId, omitString } from "@/lib/common";
import { RootState } from "@/reducers/types";

type Props = {
  data?: ScheduleResult;
  fetching: boolean;
  scheduleDate: Date;
  total: number;
  onPrevPage: () => void;
  onNextPage: () => void;
  onJumpPage: (page) => void;
};

export const ResultList: FC<Props> = ({ data, fetching, scheduleDate, total, onPrevPage, onNextPage, onJumpPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const constructionId = getConstructionId();
  const { currentPageOfResultList, isLoadingResults } = useSelector((state: RootState) => state.schedule);
  const [page, pageTotal] = useMemo(() => {
    return [total === 0 ? 0 : currentPageOfResultList, Math.ceil(total / SCHEDULE_DATA_PER_PAGE)];
  }, [total, currentPageOfResultList]);

  const headerDateLabel = useMemo(() => {
    return moment(scheduleDate).subtract(1, "day").format("MM/DD");
  }, [scheduleDate]);

  const tableBodyRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (tableBodyRef.current?.scrollTop) {
      tableBodyRef.current.scrollTop = 0;
    }
  }, [currentPageOfResultList]);

  const handleClickItemName = (path: string, itemId: number) => {
    dispatch(
      actions.matrix.fetchSubject(itemId, (response) => {
        window.open(
          `${path}&item_id=${itemId}&primary_charge_id=${response.primary_charge_id || ""}`,
          "_blank",
          "noreferrer"
        );
      })
    );
  };

  return (
    <div className="schedule-table-container">
      <div className="description">
        {t("schedule_result_header", { date: headerDateLabel, totalWorkers: data?.total_worker_num })}
      </div>
      <div className={clsx("result-list", fetching ? "loading loading--list" : "")}>
        <div className="header-row flex">
          <div className="col1">{t("device")}</div>
          <div className="col2">{t("title")}</div>
          <div className="col3">{t("content")}</div>
          <div className="vertical-text col4">{t("number_of_workers")}</div>
          <div className="vertical-text col5 last-column">{t("completion_or_handover")}</div>
          <div className="scroll-margin" />
        </div>
        <div key={`result-list-body-${isLoadingResults}`} className="body-area" ref={tableBodyRef}>
          {data?.list.map((item, index) => {
            return (
              <div key={`result-list-row-${index}`} className="body-row">
                <GridCell className="col1" align="left">
                  {item.device_name}
                </GridCell>
                <GridCell className="col2 break-word" align="left">
                  <a
                    onClick={() =>
                      handleClickItemName(`/#/${constructionId}/matrix?category_id=${item.category_id}`, item.item_id)
                    }
                  >
                    {omitString(item.item_name, 22)}
                  </a>
                </GridCell>
                <GridCell className="col3 break-word" align="left">
                  {omitString(item.process_name, 37)}
                </GridCell>
                <GridCell className="col4">{item.field_t1}</GridCell>
                <GridCell className="col5">{item.result_type_name}</GridCell>
              </div>
            );
          })}
        </div>
        <div className="paging-footer">
          <FooterPager
            currentPage={page}
            maxPage={pageTotal}
            onPrev={onPrevPage}
            onNext={onNextPage}
            onJump={onJumpPage}
          />
        </div>
      </div>
    </div>
  );
};
