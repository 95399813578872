import { connect } from "react-redux";

import actions from "../../actions/index";
import Asset from "../../components/asset/Asset";

const mapStateToProps = (state) => {
  let areas = [];
  let devices = [];
  let categories = [];
  let facilityManagements = [];
  let constructionManagements = [];
  let primaryCharges = [];
  let others = [];
  let processMajorClasses = [];
  let checkpoints = [];
  let systems = [];
  let companies = [];
  let users = [];
  let groups = [];

  if (state.construction.masters) {
    areas = state.construction.masters.areas;
    devices = state.construction.masters.devices;
    categories = state.construction.masters.categories;
    facilityManagements = state.construction.masters.facility_managements;
    constructionManagements = state.construction.masters.construction_managements;
    primaryCharges = state.construction.masters.primary_charges;
    others = state.construction.masters.others;
    processMajorClasses = state.construction.masters.process_major_classes;
    checkpoints = state.construction.masters.checkpoints;
    systems = state.construction.masters.systems;
    companies = state.construction.masters.companies;
    users = state.construction.masters.users;
    groups = state.construction.masters.groups;
  }

  return {
    masters: {
      areas,
      devices,
      categories,
      facilityManagements,
      constructionManagements,
      primaryCharges,
      others,
      processMajorClasses,
      checkpoints,
      systems,
      companies,
      users,
      groups,
    },
    categoryIds: state.asset.categoryIds,
    insertStartDate: state.asset.insertStartDate,
    insertEndDate: state.asset.insertEndDate,
    fileName: state.asset.fileName,
    uploadTarget: state.asset.uploadTarget,
    items: state.asset.items,
    fetching: state.asset.fetching,
    isCreate: state.asset.isCreate,
    isError: state.asset.isError,
    validated: state.asset.validated,
    validRequiredParameter: state.qrcode.validRequiredParameter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeCategory: (item) => dispatch(actions.asset.changeCategory(item)),
    changeFilename: (fileName) => dispatch(actions.asset.changeFilename(fileName)),
    changeUploadTarget: (uploadId) => dispatch(actions.asset.changeUploadTarget(uploadId)),
    search: (params, callback) => dispatch(actions.asset.search(params, callback)),
    changeDateFrom: (params, callback) => dispatch(actions.asset.changeDateFrom(params, callback)),
    changeDateTo: (params, callback) => dispatch(actions.asset.changeDateTo(params, callback)),
    clearSearch: (target) => dispatch(actions.asset.clearSearch(target)),
    revertLocalCondition: () => dispatch(actions.qrcode.revertLocalCondition()),
    download: (params, filetype, callback, fallback) =>
      dispatch(actions.asset.download(params, filetype, callback, fallback)),
    createZip: (params, callback) => dispatch(actions.asset.createZip(params, callback)),
    deleteItem: (params, callback) => dispatch(actions.asset.deleteItem(params, callback)),
    downloadList: (kind, fileId, qrFlag, callback, options) =>
      dispatch(actions.common.downloadAttachmentFile(kind, fileId, qrFlag, callback, options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Asset);
