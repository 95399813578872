import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { LANG_OPTIONS } from "@/constants";
import styles from "../constants/reactSelectStyle";

import PasswordEditor from "./PasswordEditor";
import PasswordWarning from "./PasswordWarning";
import TwoFactorAuth from "./TwoFactorAuth";

import { AuthResult } from "@/models/authentication";

type Props = {
  name: string;
  password: string;
  error: { [key: string]: string };
  authResult: AuthResult;
  visiblePasswordWarning: boolean;
  visiblePasswordEditor: boolean;
  visibleTwoFactorAuth: boolean;

  lang: string;

  changeName: (name) => void;
  changePassword: (password) => void;
  submit: (name, password) => void;

  savePassword: (oldPassword, newPassword, okHandler, ngHandler) => void;
  verifyTwoFactorAuth: (security_code, ng_handler) => void;
  changeLang: (id) => void;

  showConfirm: (title, messages, okClickHandler, cancelClickHandler) => void;
  hideConfirm: () => void;
  hidePasswordWarning: () => void;
  showPasswordEditor: () => void;
  hidePasswordEditor: () => void;
  hideTwoFactorAuth: () => void;
  setUnloadAlert: () => void;
  clearUnloadAlert: () => void;
};

const Login = (props: Props) => {
  const { t } = useTranslation();
  const buttonRef = useRef(null);
  const handleChangeName = (e) => props.changeName(e.target.value);
  const handleChangePassword = (e) => props.changePassword(e.target.value);
  const handleSubmit = (e) => {
    if (buttonRef.current) {
      buttonRef.current.blur();
    }

    e.preventDefault();
    props.submit(props.name, props.password);
  };

  const handleClearStorage = (e) => {
    e.preventDefault();

    props.showConfirm(
      t("confirmation"),
      [t("clear_browser_history_sure")],
      () => {
        localStorage.clear();
        sessionStorage.clear();
        props.hideConfirm();
      },
      props.hideConfirm
    );
  };

  return (
    <div className="wrapper">
      <div id="contents">
        <div className="login-area">
          <form className="login-box" onSubmit={handleSubmit}>
            <div className="logo">
              {t("sankyu_system_name1")}
              <br />
              {t("sankyu_system_name2")}
              <br />
              {t("sankyu_system_name3")}
              <strong>
                <span className="sx-progress">SX-Progress</span>
                <span> / </span>
                <span className="layout">Layout</span>
              </strong>
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder={t("user_name")}
                className="form-control"
                value={props.name}
                data-test-id="text-login-login-id"
                onChange={handleChangeName}
              />
              {props.error.name && <p className="login-error-message">{t(props.error.name)}</p>}
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder={t("password")}
                className="form-control"
                value={props.password}
                data-test-id="text-login-password"
                onChange={handleChangePassword}
              />
              {props.error.password && <p className="login-error-message">{t(props.error.password)}</p>}
            </div>
            <div className="form-group" style={{ textAlign: "left" }}>
              <Select
                styles={styles}
                options={LANG_OPTIONS}
                onChange={props.changeLang}
                value={LANG_OPTIONS.find((option) => option.value === props.lang)}
                menuPortalTarget={document.querySelector("body")}
              />
            </div>
            <div className="btn-area">
              <button ref={buttonRef} type="submit" className="btn btn-blue" data-test-id="button-login">
                {t("login")}
              </button>
            </div>
            <div className="btn-clear-storage">
              <button onClick={handleClearStorage} type="button">
                {t("clear_browser_history")}
              </button>
            </div>
          </form>
          <div className="recommended-env">
            <dl className="clearfix">
              <dt className="subject">{t("recommended_env")}</dt>
              <dd></dd>
              <dt>Windows</dt>
              <dd>
                {t("chrome")}
                <br />
                {t("edge")}
              </dd>
            </dl>
          </div>
        </div>
        {props.visiblePasswordWarning && (
          <PasswordWarning
            expireDate={props.authResult ? props.authResult.password_expire_date : ""}
            onChangePasswordClick={props.showPasswordEditor}
            onClose={props.hidePasswordWarning}
          />
        )}
        {props.visiblePasswordEditor && (
          <PasswordEditor
            setUnloadAlert={props.setUnloadAlert}
            clearUnloadAlert={props.clearUnloadAlert}
            close={props.hidePasswordEditor}
            save={props.savePassword}
          />
        )}
        {props.visibleTwoFactorAuth && (
          <TwoFactorAuth
            setUnloadAlert={props.setUnloadAlert}
            clearUnloadAlert={props.clearUnloadAlert}
            close={props.hideTwoFactorAuth}
            verify={props.verifyTwoFactorAuth}
            securityCodeType={props.authResult.security_code_type}
            securityCodeDestination={props.authResult.security_code_destination}
          />
        )}
      </div>
    </div>
  );
};

export default Login;
