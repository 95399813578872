import { FetchScreenConfigParams, UpdateScreenConfigParams } from "@/actions/types/screenConfig";
import * as constants from "@/constants";
import environments from "@/environments";

export const fetchScreenConfig = (params: FetchScreenConfigParams, callback?) => {
  const endpoint = `${environments.API_BASE_URI}/screen_configs`;

  const query = new URLSearchParams();
  query.set("screen_name", params.screen_name);
  query.set("config_type", params.config_type.toString());

  return {
    type: constants.APP_CALL_API,
    endpoint: `${endpoint}?${query.toString()}`,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.SCREEN_CONFIG_BEGIN_FETCH,
        };
      },
      success: (response) => {
        callback?.(response?.screen_config ?? null);

        return {
          type: constants.SCREEN_CONFIG_END_FETCH,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const updateScreenConfig = (params: UpdateScreenConfigParams, callback?) => {
  const endpoint = `${environments.API_BASE_URI}/screen_configs/update`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(params),
    callbacks: {
      begin: () => {
        return {
          type: constants.SCREEN_CONFIG_BEGIN_UPDATE,
        };
      },
      success: (response) => {
        callback?.(response?.screen_config ?? []);

        return {
          type: constants.SCREEN_CONFIG_END_UPDATE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
