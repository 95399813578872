import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import * as util from "../lib/common";

import Modal from "./Modal";
import Select from "./common/Select";

class ConstructionSelector extends Component {
  constructor(props) {
    super(props);

    const { customerId, placeId, constructionId } = this.props;

    this.state = {
      customerId,
      placeId,
      constructionId,
      error: {},
    };

    this.handleChangeCustomer = this.handleChangeCustomer.bind(this);
    this.handleChangePlace = this.handleChangePlace.bind(this);
    this.handleChangeConstruction = this.handleChangeConstruction.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleChangeCustomer(value) {
    this.setState({
      customerId: value,
      placeId: 0,
      constructionId: 0,
    });
  }

  handleChangePlace(value) {
    this.setState({
      placeId: value,
      constructionId: 0,
    });
  }

  handleChangeConstruction(value) {
    this.setState({
      constructionId: value,
    });
  }

  handleCancel() {
    const { t } = this.props;

    if (!util.getConstructionId()) {
      this.props.showAlert(t("alert"), [t("select_construction")], this.props.hideAlert);
    } else {
      this.props.close();
    }
  }

  handleSave() {
    const error = {};
    const { customerId, placeId, constructionId } = this.state;
    const { t } = this.props;

    if (customerId === 0 || placeId === 0 || constructionId === 0) {
      error.summary = t("select_all");
    }

    if (Object.keys(error).length > 0) {
      this.setState({ error });
    } else {
      history.replaceState(undefined, undefined, `/#/${constructionId}/home`);
      this.props.save(constructionId);
      this.props.onSave();
    }
  }

  render() {
    const { constructions, customers, places, t } = this.props;
    const { customerId, placeId, constructionId, error } = this.state;

    return (
      <Modal title={t("switch_construction")} closeHandler={this.handleCancel}>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group w-350">
              <span className="form-label txt-bold">{t("customer_name")}</span>
              <div className="w-290 d-ib ta-l">
                <Select
                  prefix="customer"
                  options={customers}
                  value={customerId}
                  onChange={(value) => this.handleChangeCustomer(value)}
                  isMulti={false}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group w-350">
              <span className="form-label txt-bold">{t("construction_location")}</span>
              <div className="w-290 d-ib ta-l">
                <Select
                  prefix="place"
                  options={places.filter((place) => place.customer_id === customerId)}
                  value={placeId}
                  onChange={(value) => this.handleChangePlace(value)}
                  isMulti={false}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group w-350">
              <span className="form-label txt-bold">{t("construction_name")}</span>
              <div className="w-290 d-ib ta-l">
                <Select
                  prefix="construction"
                  options={constructions.filter(
                    (construction) => construction.customer_id === customerId && construction.place_id === placeId
                  )}
                  value={constructionId}
                  onChange={(value) => this.handleChangeConstruction(value)}
                  isMulti={false}
                />
              </div>
            </div>
            {error.summary && (
              <div className="form-error w-350">
                <p className="form-error-message w-290">{error.summary}</p>
              </div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button
            data-test-id="button-construction-cancel"
            type="button"
            className="btn btn-gray"
            onClick={this.handleCancel}
          >
            {t("cancel")}
          </button>
          <button
            data-test-id="button-construction-save"
            type="button"
            className="btn btn-blue"
            onClick={this.handleSave}
          >
            {t("decision")}
          </button>
        </div>
      </Modal>
    );
  }
}

ConstructionSelector.propTypes = {
  customerId: PropTypes.number.isRequired,
  placeId: PropTypes.number.isRequired,
  constructionId: PropTypes.number.isRequired,
  customers: PropTypes.array.isRequired,
  places: PropTypes.array.isRequired,
  constructions: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  hideAlert: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  onSave: PropTypes.func,
};

export default withTranslation()(ConstructionSelector);
