import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import DataExportListDrawerContainer from "../containers/DataExportListDrawerContainer";
import NavigationContainer from "../containers/NavigationContainer";

import { DataExportNotificationIcon } from "./DataExportListDrawer/components/DataExportNotificationIcon";
import ProgressNotificationDrawer from "./ProgressNotificationDrawer";
import ProgressNotificationBell from "./ProgressNotificationDrawer/components/ProgressNotificationBell";

import { Construction, Customer, Place } from "@/models/construction";

type Props = {
  construction: Construction;
  customer: Customer;
  place: Place;
  fetchingProgressRate: boolean;
  userName: string;
  resultProgressRate: number;
  scheduleProgressRate: number;
  logout: () => void;
  showConstructionSelector: () => void;
  showPasswordEditor: () => void;
  reloadProgressRate: () => void;
  fetchJobs: () => void;
  fetchedJobsLargestId: number;
  notifications: any;
  showLangSelector: () => void;
};

const Header: FC<Props> = (props) => {
  const { t } = useTranslation();

  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showDatalist, setShowDatalist] = useState(false);
  const [isNotificationDrawerVisible, setIsNotificationDrawerVisible] = useState(false);
  const [fetchedJobsLargestIdBeforeOpenDatalist, setFetchedJobsLargestIdBeforeOpenDatalist] = useState(0);

  const showConstructionSelector = () => {
    props.showConstructionSelector();
  };

  const toggleDatalist = () => {
    if (!showDatalist) {
      props.fetchJobs();
    }

    setShowDatalist(!showDatalist);
    setFetchedJobsLargestIdBeforeOpenDatalist(props.fetchedJobsLargestId);
    setIsNotificationDrawerVisible(!showDatalist ? false : isNotificationDrawerVisible);
  };

  const closeDatalist = () => {
    if (showDatalist) {
      setShowDatalist(false);
    }
  };

  const onNotificationDrawerToggle = () => {
    setShowDatalist(false);
    setIsNotificationDrawerVisible(!isNotificationDrawerVisible);
  };

  const onNotificationDrawerClose = () => {
    setIsNotificationDrawerVisible(false);
  };

  const {
    construction,
    customer,
    place,
    fetchingProgressRate,
    userName,
    logout,
    reloadProgressRate,
    showPasswordEditor,
    showLangSelector,
    resultProgressRate,
    scheduleProgressRate,
    notifications,
  } = props;

  const resRate =
    !fetchingProgressRate && (resultProgressRate || resultProgressRate === 0) ? resultProgressRate : "  --  ";
  const scheRate =
    !fetchingProgressRate && (scheduleProgressRate || scheduleProgressRate === 0) ? scheduleProgressRate : "  --  ";

  const constructionBreadCrumbs = useMemo(
    () =>
      [customer?.customer_name ?? "", place?.place_name ?? "", construction?.construction_name ?? ""]
        .filter((v) => !!v)
        .join(" | "),
    [customer, place, construction]
  );

  return (
    <header className="header">
      <div className="header-sub">
        <div className="inner">
          <div className="customer-information" onClick={() => showConstructionSelector()}>
            {constructionBreadCrumbs}
          </div>
          <div className="progress">
            {`${t("progress")} : ${resRate}% / ${scheRate}%`}
            <div
              className={`btn-reload ${fetchingProgressRate ? "animation-rotate-progress" : ""}`}
              onClick={reloadProgressRate}
            >
              <img src="./img/icon-reload-gray.svg" alt="reload" />
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix">
        <div className="header-left">
          <NavigationContainer closeHandler={closeDatalist} />
        </div>
        <div className="header-right">
          <nav
            className="user-nav"
            onMouseLeave={() => setShowUserMenu(false)}
            onMouseEnter={() => setShowUserMenu(true)}
          >
            <ul>
              <li data-test-id="button-header-user_nav">
                <span className="icon-menu">
                  <img src="./img/icon-menu-white.svg" />
                </span>
                {showUserMenu && (
                  <ul className="dropdown-menu" onClick={() => setShowUserMenu(false)}>
                    <li className="user-name">{userName}</li>
                    <li>
                      <a data-test-id="button-header-manual" href="/api/manual" target="_blank">
                        {t("operation_manual")}
                      </a>
                    </li>
                    <li>
                      <a data-test-id="button-header-change_password" onClick={showPasswordEditor}>
                        {t("change_password")}
                      </a>
                    </li>
                    <li>
                      <a data-test-id="button-header-select_language" onClick={showLangSelector}>
                        {t("language")}
                      </a>
                    </li>
                    <li>
                      <a data-test-id="button-header-log_out" onClick={logout}>
                        {t("log_out")}
                      </a>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </nav>
          {construction?.construction_id > 0 && (
            <React.Fragment>
              <DataExportNotificationIcon
                count={notifications.summary.download_job.not_read_num}
                onClick={toggleDatalist}
              />
              <ProgressNotificationBell
                count={notifications.summary.progress_notification.not_read_num}
                onClick={onNotificationDrawerToggle}
              />
            </React.Fragment>
          )}
        </div>
        <DataExportListDrawerContainer
          open={showDatalist}
          onClose={closeDatalist}
          fetchedJobsLargestIdBeforeOpenDatalist={fetchedJobsLargestIdBeforeOpenDatalist}
        />
        <ProgressNotificationDrawer visible={isNotificationDrawerVisible} onClose={onNotificationDrawerClose} />
      </div>
    </header>
  );
};

export default Header;
