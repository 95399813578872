import actions from "../actions";
import * as constants from "../constants";
import * as util from "../lib/common";
import restoreState from "../lib/restoreState";
import storageManager from "../lib/storageManager";
import { RootState } from "@/reducers/types";

const stateUrl = (store) => (next) => (action) => {
  // 最新の状態を得るために最後にhash書き換え処理を行う必要がある
  next(action);

  if (action.type === constants.APP_CALL_API || action.type.indexOf("LOGIN_") === 0) {
    return;
  }

  if (action.type === constants.LOGOUT_SUCCESS) {
    history.replaceState(undefined, undefined, "/");

    return;
  }

  // 未ダウンロード数取得API実行時は書き換えない
  if (
    action.type === constants.NOTIFICATIONS_BEGIN_FETCH_SUMMARY ||
    action.type === constants.NOTIFICATIONS_END_FETCH_SUMMARY
  ) {
    return;
  }

  const state: RootState = store.getState();
  const constructionId = state.construction.constructionId;
  let hash = "";
  let menu = state.session.system === "layout" ? state.layoutApp.layoutMenu : state.app.menu;

  if (constructionId === 0 || !state.session.valid) {
    return;
  }

  const funcLayout = state.construction?.construction?.func_layout;
  const funcProgress = state.construction?.construction?.func_progress;
  if (funcLayout === 0 && funcProgress === 0) {
    store.dispatch({
      type: constants.APP_SHOW_ERROR,
      payload: { errors: null },
    });

    store.dispatch(actions.login.shouldLogout());

    return;
  }

  const urlState = restoreState();
  const initialMenu = urlState?.menu;
  let defaultSystem = "progress";

  if (initialMenu === "machine" || initialMenu === "machine_type" || initialMenu === "plot_plan") {
    defaultSystem = "layout";
  }

  if (defaultSystem === "layout" && funcLayout === 0 && funcProgress === 1) {
    defaultSystem = "progress";
  } else if (defaultSystem === "progress" && funcLayout === 1 && funcProgress === 0) {
    defaultSystem = "layout";
  }

  if (!util.getMenuByName(initialMenu)) {
    defaultSystem = "progress";

    if (defaultSystem === "progress") {
      history.replaceState(undefined, undefined, `#/${constructionId}/home`);
      store.dispatch(actions.app.changeMenu("home"));
      storageManager.setUserItem("system", "progress");

      return;
    }
  }

  if (menu === "dataimport") {
    menu = "import";
  }

  hash = `/${constructionId}/${menu}`;

  if (menu === "summary") {
    const { areaIds, deviceIds, categoryIds, facilityManagementIds, constructionManagementIds, primaryChargeIds } =
      state.summary;

    const params = new URLSearchParams();

    if (deviceIds.length > 0) {
      params.append("device_ids", deviceIds.join(","));
    }

    if (areaIds.length > 0) {
      params.append("area_ids", areaIds.join(","));
    }

    if (categoryIds.length > 0) {
      params.append("category_ids", categoryIds.join(","));
    }

    if (facilityManagementIds.length > 0) {
      params.append("facility_management_ids", facilityManagementIds.join(","));
    }

    if (constructionManagementIds.length > 0) {
      params.append("construction_management_ids", constructionManagementIds.join(","));
    }

    if (primaryChargeIds.length > 0) {
      params.append("primary_charge_ids", primaryChargeIds.join(","));
    }

    if (params.toString() !== "") {
      hash = `${hash}?${params.toString()}`;
    }
  } else if (menu === "matrix") {
    const {
      categoryId,
      primaryChargeId,
      areaIds,
      deviceIds,
      facilityManagementIds,
      constructionManagementIds,
      otherIds,
      systemIds,
      userIds,
      groupIds,
      companyIds,
      processMiddleClassIds,
      itemText,
      processText,
      regulation,
      filters,
    } = state.matrixSearch;
    const params = new URLSearchParams();

    if (categoryId > 0) {
      params.append("category_id", categoryId);
    }

    if (primaryChargeId > 0) {
      params.append("primary_charge_id", primaryChargeId);
    }

    if (deviceIds.length > 0) {
      params.append("device_ids", deviceIds.join(","));
    }

    if (areaIds.length > 0) {
      params.append("area_ids", areaIds.join(","));
    }

    if (facilityManagementIds.length > 0) {
      params.append("facility_management_ids", facilityManagementIds.join(","));
    }

    if (constructionManagementIds.length > 0) {
      params.append("construction_management_ids", constructionManagementIds.join(","));
    }

    if (otherIds.length > 0) {
      params.append("other_ids", otherIds.join(","));
    }

    if (systemIds.length > 0) {
      params.append("system_ids", systemIds.join(","));
    }

    if (userIds.length > 0) {
      params.append("user_ids", userIds.join(","));
    }

    if (groupIds.length > 0) {
      params.append("group_ids", groupIds.join(","));
    }

    if (companyIds.length > 0) {
      params.append("company_ids", companyIds.join(","));
    }

    if (processMiddleClassIds.length > 0) {
      params.append("process_middle_class_ids", processMiddleClassIds.join(","));
    }

    if (itemText !== "") {
      params.append("item_text", itemText);
    }

    if (processText !== "") {
      params.append("process_text", processText);
    }

    if (regulation !== "") {
      params.append("regulation", regulation);
    }

    if (filters.length > 0) {
      params.append("filter", filters.join(","));
    }

    const urlState = restoreState();

    if (urlState.item_id) {
      params.append("item_id", urlState.item_id);
    }

    if (urlState.process_id) {
      params.append("process_id", urlState.process_id);
    }

    if (params.toString() !== "") {
      hash = `${hash}?${params.toString()}`;
    }
  } else if (menu === "approval") {
    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      processMiddleClassIds,
      companyIds,
      scheduleEndDate,
      checkpointIds,
      itemText,
      processText,
      filter,
    } = state.approval;
    const params = new URLSearchParams();

    if (categoryId > 0) {
      params.set("category_id", categoryId);
    }

    if (deviceIds.length > 0) {
      params.append("device_ids", deviceIds.join(","));
    }

    if (areaIds.length > 0) {
      params.append("area_ids", areaIds.join(","));
    }

    if (facilityManagementIds.length > 0) {
      params.append("facility_management_ids", facilityManagementIds.join(","));
    }

    if (constructionManagementIds.length > 0) {
      params.append("construction_management_ids", constructionManagementIds.join(","));
    }

    if (primaryChargeIds.length > 0) {
      params.append("primary_charge_ids", primaryChargeIds.join(","));
    }

    if (otherIds.length > 0) {
      params.append("other_ids", otherIds.join(","));
    }

    if (processMajorClassIds.length > 0) {
      params.append("process_major_class_ids", processMajorClassIds.join(","));
    }

    if (processMiddleClassIds.length > 0) {
      params.append("process_middle_class_ids", processMiddleClassIds.join(","));
    }

    if (companyIds.length > 0) {
      params.append("company_ids", companyIds.join(","));
    }

    if (scheduleEndDate !== "") {
      params.append("schedule_end_date", scheduleEndDate.replace(/\//g, "-"));
    }

    if (checkpointIds.length > 0) {
      params.append("checkpoint_ids", checkpointIds.join(","));
    }

    if (itemText !== "") {
      params.append("item_text", itemText);
    }

    if (processText !== "") {
      params.append("process_text", processText);
    }

    if (filter.length > 0) {
      params.append("filter", filter.join(","));
    }

    if (params.toString() !== "") {
      hash = `${hash}?${params.toString()}`;
    }
  } else if (menu === "inspection") {
    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      processMajorClassIds,
      scheduleDate,
      checkpointIds,
      itemText,
      processText,
      filter,
    } = state.inspection;
    const params = new URLSearchParams();

    if (categoryId > 0) {
      params.set("category_id", categoryId);
    }

    if (deviceIds.length > 0) {
      params.append("device_ids", deviceIds.join(","));
    }

    if (areaIds.length > 0) {
      params.append("area_ids", areaIds.join(","));
    }

    if (facilityManagementIds.length > 0) {
      params.append("facility_management_ids", facilityManagementIds.join(","));
    }

    if (constructionManagementIds.length > 0) {
      params.append("construction_management_ids", constructionManagementIds.join(","));
    }

    if (primaryChargeIds.length > 0) {
      params.append("primary_charge_ids", primaryChargeIds.join(","));
    }

    if (otherIds.length > 0) {
      params.append("other_ids", otherIds.join(","));
    }

    if (processMajorClassIds.length > 0) {
      params.append("process_major_class_ids", processMajorClassIds.join(","));
    }

    if (scheduleDate !== "") {
      params.append("schedule_date", scheduleDate.replace(/\//g, "-"));
    }

    if (checkpointIds.length > 0) {
      params.append("checkpoint_ids", checkpointIds.join(","));
    }

    if (itemText !== "") {
      params.append("item_text", itemText);
    }

    if (processText !== "") {
      params.append("process_text", processText);
    }

    if (filter.length > 0) {
      params.append("filter", filter.join(","));
    }

    if (params.toString() !== "") {
      hash = `${hash}?${params.toString()}`;
    }
  } else if (menu === "schedule_eneos_kawasaki") {
    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field7Ids,
      field8Ids,
      userIds,
      groupIds,
      scheduleDate,
      itemText,
      processText,
    } = state.schedule_eneos_kawasaki;
    const params = new URLSearchParams();

    if (categoryId > 0) {
      params.set("category_id", categoryId);
    }

    if (deviceIds.length > 0) {
      params.append("device_ids", deviceIds.join(","));
    }

    if (areaIds.length > 0) {
      params.append("area_ids", areaIds.join(","));
    }

    if (facilityManagementIds.length > 0) {
      params.append("facility_management_ids", facilityManagementIds.join(","));
    }

    if (constructionManagementIds.length > 0) {
      params.append("construction_management_ids", constructionManagementIds.join(","));
    }

    if (primaryChargeIds.length > 0) {
      params.append("primary_charge_ids", primaryChargeIds.join(","));
    }

    if (field1Ids.length > 0) {
      params.append("field1", field1Ids.join(","));
    }

    if (field7Ids.length > 0) {
      params.append("field7", field7Ids.join(","));
    }

    if (field8Ids.length > 0) {
      params.append("field8", field8Ids.join(","));
    }

    if (userIds.length > 0) {
      params.append("user_id", userIds.join(","));
    }

    if (groupIds.length > 0) {
      params.append("group_id", groupIds.join(","));
    }

    if (scheduleDate !== "") {
      params.append("schedule_date", scheduleDate.replace(/\//g, "-"));
    }

    if (itemText !== "") {
      params.append("item_text", itemText);
    }

    if (processText !== "") {
      params.append("process_text", processText);
    }

    if (params.toString() !== "") {
      hash = `${hash}?${params.toString()}`;
    }
  } else if (menu === "schedule_chita") {
    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      field1Ids,
      field2Ids,
      field3Ids,
      field4Ids,
      scheduleDate,
      itemText,
      processText,
    } = state.schedule_chita;
    const params = new URLSearchParams();

    if (categoryId > 0) {
      params.set("category_id", categoryId);
    }

    if (deviceIds.length > 0) {
      params.append("device_ids", deviceIds.join(","));
    }

    if (areaIds.length > 0) {
      params.append("area_ids", areaIds.join(","));
    }

    if (facilityManagementIds.length > 0) {
      params.append("facility_management_ids", facilityManagementIds.join(","));
    }

    if (constructionManagementIds.length > 0) {
      params.append("construction_management_ids", constructionManagementIds.join(","));
    }

    if (primaryChargeIds.length > 0) {
      params.append("primary_charge_ids", primaryChargeIds.join(","));
    }

    if (field1Ids.length > 0) {
      params.append("field1_ids", field1Ids.join(","));
    }

    if (field2Ids.length > 0) {
      params.append("field2_ids", field2Ids.join(","));
    }

    if (field3Ids.length > 0) {
      params.append("field3_ids", field3Ids.join(","));
    }

    if (field4Ids.length > 0) {
      params.append("field4_ids", field4Ids.join(","));
    }

    if (scheduleDate !== "") {
      params.append("schedule_date", scheduleDate.replace(/\//g, "-"));
    }

    if (itemText !== "") {
      params.append("item_text", itemText);
    }

    if (processText !== "") {
      params.append("process_text", processText);
    }

    if (params.toString() !== "") {
      hash = `${hash}?${params.toString()}`;
    }
  }

  if (menu === "import") {
    const { categoryId, fileName, statusId, import_start_date, import_end_date, userId, fileType } = state.dataimport;
    const params = new URLSearchParams();

    if (categoryId > 0) {
      params.append("category_id", categoryId);
    }

    if (fileName && fileName.length > 0) {
      params.append("file_name", fileName);
    }

    if (statusId > -1) {
      params.append("status", statusId);
    }

    if (import_start_date && import_start_date.length > 0) {
      params.append("import_date_from", import_start_date);
    }

    if (import_end_date && import_end_date.length > 0) {
      params.append("import_date_to", import_end_date);
    }

    if (userId > 0) {
      params.append("user_id", userId);
    }

    if (fileType > 0) {
      params.append("format", fileType);
    }

    if (params.toString() !== "") {
      hash = `${hash}?${params.toString()}`;
    }
  } else if (menu === "qrcode") {
    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      companyId,
      userId,
      groupId,
    } = state.qrcode;
    const params = new URLSearchParams();

    if (categoryId > 0) {
      params.set("category_id", categoryId);
    }

    if (deviceIds.length > 0) {
      params.append("device_ids", deviceIds.join(","));
    }

    if (areaIds.length > 0) {
      params.append("area_ids", areaIds.join(","));
    }

    if (facilityManagementIds.length > 0) {
      params.append("facility_management_ids", facilityManagementIds.join(","));
    }

    if (constructionManagementIds.length > 0) {
      params.append("construction_management_ids", constructionManagementIds.join(","));
    }

    if (primaryChargeIds.length > 0) {
      params.append("primary_charge_ids", primaryChargeIds.join(","));
    }

    if (otherIds.length > 0) {
      params.append("other_ids", otherIds.join(","));
    }

    if (companyId) {
      params.set("companyId", companyId);
    }

    if (userId) {
      params.set("user_id", userId);
    }

    if (groupId) {
      params.set("group_id", groupId);
    }

    if (params.toString() !== "") {
      hash = `${hash}?${params.toString()}`;
    }
  }
  if (menu === "asset") {
    const { categoryIds, fileName, insertStartDate, insertEndDate, uploadTarget } = state.asset;

    const params = new URLSearchParams();

    if (categoryIds.length > 0) {
      params.append("category_id", categoryIds.join(","));
    }

    if (fileName && fileName.length > 0) {
      params.append("file_name", fileName);
    }

    if (insertStartDate && insertStartDate.length > 0) {
      params.append("insert_start_date", insertStartDate);
    }

    if (insertEndDate && insertEndDate.length > 0) {
      params.append("insert_end_date", insertEndDate);
    }

    if (uploadTarget.length > 0) {
      params.append("upload_target", uploadTarget.join(","));
    }

    if (params.toString() !== "") {
      hash = `${hash}?${params.toString()}`;
    }
  }

  if (menu === "system") {
    const {
      areaIds,
      deviceIds,
      categoryId,
      facilityManagementIds,
      constructionManagementIds,
      primaryChargeIds,
      otherIds,
      systemName,
      note,
      scheStartDate,
      scheEndDate,
      closeStartDate,
      closeEndDate,
      totalTest,
      preparation,
    } = state.system;
    const params = new URLSearchParams();

    if (categoryId > 0) {
      params.set("category_id", categoryId);
    }

    if (deviceIds.length > 0) {
      params.append("device_ids", deviceIds.join(","));
    }

    if (areaIds.length > 0) {
      params.append("area_ids", areaIds.join(","));
    }

    if (facilityManagementIds.length > 0) {
      params.append("facility_management_ids", facilityManagementIds.join(","));
    }

    if (constructionManagementIds.length > 0) {
      params.append("construction_management_ids", constructionManagementIds.join(","));
    }

    if (primaryChargeIds.length > 0) {
      params.append("primary_charge_ids", primaryChargeIds.join(","));
    }

    if (otherIds.length > 0) {
      params.append("other_ids", otherIds.join(","));
    }

    if (systemName !== "") {
      params.append("system_name", systemName);
    }

    if (note !== "") {
      params.append("text", note);
    }

    if (scheStartDate !== "") {
      params.append("schedule_date_from", scheStartDate);
    }

    if (scheEndDate !== "") {
      params.append("schedule_date_to", scheEndDate);
    }

    if (closeStartDate !== "") {
      params.append("result_date_from", closeStartDate);
    }

    if (closeEndDate !== "") {
      params.append("result_date_to", closeEndDate);
    }

    if (totalTest !== "") {
      if (totalTest > -1) {
        params.append("totaltest_end", totalTest);
      }
    }

    if (preparation !== "") {
      params.append("preparation_flg", preparation);
    }

    if (params.toString() !== "") {
      hash = `${hash}?${params.toString()}`;
    }
  }

  // メニュー切替のみ履歴に残す
  if (action.type === constants.APP_CHANGE_MENU || action.type === constants.SUMMARY_SELECT_CATEGORY_SUMMARY) {
    const restore = restoreState();

    // 各メニューへの初回アクセス時（localStorageにデータが入っていない状態）
    // の場合は、hashがlocation.hrefが切り替わらないのでhashから組み立てる
    if (restore.menu !== action.payload) {
      history.pushState(null, null, `#${hash}`);
    } else {
      history.pushState(null, null, location.href);
    }
  } else {
    history.replaceState(undefined, undefined, `#${hash}`);
  }
};

export default stateUrl;
