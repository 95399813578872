import * as constants from "../constants/index";
import * as util from "../lib/common";

import { Construction, Customer, Place } from "@/models/construction";
import { ConstructionMasters } from "@/models/masters";

export type ConstructionState = {
  masters: ConstructionMasters;
  constructions: Construction[];
  customers: Customer[];
  places: Place[];
  constructionId: number; // TODO Remove it
  construction: Construction;
  customerId: number; // TODO Remove it
  customer: Customer;
  place: Place;
  placeId: number; // TODO Remove it
  resultProgressRate: number;
  scheduleProgressRate: number;
  fetchedExtensions: boolean;
  fetchedLinkage: boolean;
  extensions: any;
  linkageTasks: any;
};

const construction = (
  state: ConstructionState = {
    masters: null,
    constructions: [],
    customers: [],
    places: [],
    constructionId: util.getConstructionId() || 0,
    construction: null,
    customerId: 0,
    customer: null,
    placeId: 0,
    place: null,
    resultProgressRate: 0,
    scheduleProgressRate: 0,
    fetchedExtensions: false,
    fetchedLinkage: false,
    extensions: null,
    linkageTasks: null,
  },
  action
) => {
  switch (action.type) {
    case constants.LOGIN_SUCCESS: {
      const constructionId = util.getConstructionId() || 0;
      return {
        ...state,
        constructionId,
        construction: state.constructions?.find((c) => c.construction_id === constructionId) ?? null,
      };
    }
    case constants.CONSTRUCTION_END_FETCH_MASTERS: {
      return {
        ...state,
        masters: action.payload,
      };
    }
    case constants.CONSTRUCTION_END_FETCH_ITEMS: {
      const { constructions, customers, places } = action.payload;
      const construction = constructions?.find((c) => c.construction_id === state.constructionId);

      return {
        ...state,
        constructions,
        customers,
        places,
        construction,
        customerId: construction?.customer_id,
        customer: customers?.find((c) => c.customer_id === construction?.customer_id),
        placeId: construction?.place_id,
        place: places?.find((p) => p.place_id === construction?.place_id),
      };
    }
    case constants.CONSTRUCTION_END_FETCH_PROGRESS_RATE: {
      return {
        ...state,
        resultProgressRate: action.payload.result_progress_rate,
        scheduleProgressRate: action.payload.schedule_progress_rate,
      };
    }
    case constants.CONSTRUCTION_END_FETCH_EXTENSIONS: {
      return {
        ...state,
        fetchedExtensions: true,
        extensions: action.payload,
      };
    }
    case constants.CONSTRUCTION_END_FETCH_LINKAGE_TASKS: {
      return {
        ...state,
        fetchedLinkage: true,
        linkageTasks: action.payload.linkage_tasks,
      };
    }
    default:
      return state;
  }
};

export default construction;
