import enUS from "date-fns/locale/en-US";
import ja from "date-fns/locale/ja";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";

import ContentsContainer from "../containers/ContentsContainer";
import HeaderContainer from "../containers/HeaderContainer";
import LoginContainer from "../containers/LoginContainer";

import AlertDialog from "./AlertDialog";
import ConfirmDialog from "./ConfirmDialog";

import { SxLayoutApp } from "@/sx-layout/components/SxLayoutApp";

registerLocale("ja", ja);
registerLocale("en-US", enUS);
setDefaultLocale("ja");

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { appKey: 0 };
    this.showUnloadAlert = this.showUnloadAlert.bind(this);
    this.handleHashChange = this.handleHashChange.bind(this);
    this.handleSaveConstruction = this.handleSaveConstruction.bind(this);
  }

  componentDidMount() {
    window.addEventListener("popstate", this.props.popState);
    window.addEventListener("beforeunload", this.showUnloadAlert);
    window.addEventListener("hashchange", this.handleHashChange);
  }

  componentDidUpdate(prevProps) {
    const construction = this.props.construction;
    if (!!construction?.construction_id && prevProps.construction?.construction_id !== construction?.construction_id) {
      // 工事を読み込んだ or 変更したとき
      if (construction.func_progress === 1 && construction.func_layout === 0) {
        // 進捗システムのみ
        this.props.switchSystem("progress");
      } else if (construction.func_progress === 0 && construction.func_layout === 1) {
        // 重機システムのみ
        this.props.switchSystem("layout");
      }
    }
  }

  handleHashChange(e) {
    return history.replaceState(undefined, undefined, e.newURL);
  }

  showUnloadAlert(e) {
    if (this.props.unloadAlert) {
      e.preventDefault();
      e.returnValue = "";

      return "";
    }
  }

  handleSaveConstruction() {
    this.setState({ appKey: this.state.appKey + 1 }, () => {
      this.props.changeMenu("home");
    });
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.props.popState);
    window.removeEventListener("beforeunload", this.showUnloadAlert);
    window.removeEventListener("hashchange", this.handleHashChange);
  }

  render() {
    const {
      fetchingConstructions,
      fetchingMenus,
      fetchingMasters,
      fetchingRoles,
      authenticated = false,
      system,
      showConstructionSelector,
      showPasswordEditor,
      alert,
      confirm,
    } = this.props;

    const fetching = fetchingMenus || fetchingMasters || fetchingConstructions || fetchingRoles;

    if (!authenticated) {
      return (
        <React.Fragment>
          <LoginContainer />
          {alert !== null && <AlertDialog {...alert} />}
          {confirm !== null && <ConfirmDialog {...confirm} />}
        </React.Fragment>
      );
    }

    if (system === "layout") {
      return <SxLayoutApp />;
    }

    return (
      <div key={this.state.appKey} className={`wrapper ${fetching ? "loading" : ""}`}>
        <HeaderContainer showConstructionSelector={showConstructionSelector} showPasswordEditor={showPasswordEditor} />
        <ContentsContainer onSaveConstruction={this.handleSaveConstruction} />
        {confirm !== null && <ConfirmDialog {...confirm} />}
        {alert !== null && <AlertDialog {...alert} />}
      </div>
    );
  }
}

App.defaultProps = {
  confirm: null,
  alert: null,
};

App.propTypes = {
  fetchingConstructions: PropTypes.bool.isRequired,
  fetchingMenus: PropTypes.bool.isRequired,
  fetchingMasters: PropTypes.bool.isRequired,
  fetchingProgressRate: PropTypes.bool.isRequired,
  fetchingRoles: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
  showConstructionSelector: PropTypes.func.isRequired,
  showPasswordEditor: PropTypes.func.isRequired,
  confirm: PropTypes.object,
  alert: PropTypes.object,
  popState: PropTypes.func.isRequired,
  unloadAlert: PropTypes.bool.isRequired,
  changeMenu: PropTypes.func.isRequired,
  construction: PropTypes.object,
  switchSystem: PropTypes.func.isRequired,
};

export default App;
