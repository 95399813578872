import React, { FC } from "react";

type Props = {
  onClick: () => void;
};

export const ScreenConditionConfigIconButton: FC<Props> = ({ onClick }) => {
  return (
    <button className="w-[32px] p-[8px] flex justify-center items-center opacity-75" onClick={onClick}>
      <img src="./img/icon-setting-black.svg" alt="setting" />
    </button>
  );
};
