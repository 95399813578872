import _ from "lodash";
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import actions from "@/actions";
import AlertDialog from "@/components/AlertDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import LangSelectorContainer from "@/containers/LangSelectorContainer";
import PasswordEditorContainer from "@/containers/PasswordEditorContainer";
import { RootState } from "@/reducers/types";
import { ConstructionSelector } from "@/sx-layout/components/ConstructionSelector";
import { PlotMap } from "@/sx-layout/components/plotmap";
import { MachineSetting } from "@/sx-layout/components/settings/machineSetting/components/MachineSetting";
import { PlotPlanSetting } from "@/sx-layout/components/settings/plotPlanSetting/components/PlotPlanSetting";
import { useInterval } from "@/sx-layout/hooks/useInterval";
import { LayoutMenu } from "@/sx-layout/models/layoutMenu";

const NOTIFICATION_SUMMARY_POLLING_INTERVAL_IN_MS = 30000;

export const SxLayoutApp: React.FC = () => {
  const dispatch = useDispatch();

  const [isConstructionSelectorOpen, setIsConstructionSelectorOpen] = useState(false);
  const { construction } = useSelector((state: RootState) => state.construction);
  const { visiblePasswordEditor, visibleLangSelector, confirm, alert, menu } = useSelector(
    (state: RootState) => state.app
  );
  const layoutMenu = useSelector<RootState, LayoutMenu>((state) => state.layoutApp.layoutMenu);

  useEffect(() => {
    if (menu === "machine" || menu === "machine_type" || menu === "plot_plan") {
      history.pushState(undefined, undefined, `/#/${construction?.construction_id}/${menu}`);

      dispatch(actions.layoutApp.changeLayoutMenu(menu));
    }
  }, [menu]);

  useEffect(() => {
    dispatch(actions.construction.fetchItems());
    dispatch(actions.app.fetchRoles());
  }, []);

  const openConstructionSelector = useMemo(
    () => _.debounce((open: boolean) => setIsConstructionSelectorOpen(open), 500),
    []
  );

  useEffect(() => {
    if (!construction?.construction_id) {
      openConstructionSelector(true);
      return;
    }
    if (construction?.func_layout === 0) return;
    dispatch(actions.app.fetchMenus());
    dispatch(actions.construction.fetchExtensions());
    dispatch(actions.construction.fetchMasters());
    dispatch(actions.layoutMasters.fetchLayoutMasters());
    dispatch(actions.notifications.fetchNotificationsSummary());
    dispatch(actions.machine.fetchCompaniesMachineTypes());
    dispatch(actions.machine.fetchMachineTypes());
    openConstructionSelector(false);
  }, [construction?.construction_id]);

  useInterval(() => {
    if (construction?.construction_id) {
      dispatch(actions.notifications.fetchNotificationsSummary());
    }
  }, NOTIFICATION_SUMMARY_POLLING_INTERVAL_IN_MS);

  const onSaveLang = () => {
    dispatch(actions.layoutApp.changeLayoutMenu("machine"));
  };

  if (!construction || construction?.func_layout === 0) {
    return <></>;
  }

  return (
    <div id="sx-layout">
      {layoutMenu === "machine" && <PlotMap />}
      {layoutMenu === "machine_type" && <MachineSetting />}
      {layoutMenu === "plot_plan" && <PlotPlanSetting />}
      {visiblePasswordEditor && <PasswordEditorContainer />}
      {visibleLangSelector && <LangSelectorContainer onSave={onSaveLang} />}
      {confirm !== null && <ConfirmDialog {...confirm} styleSxLayout={true} />}
      {alert !== null && <AlertDialog {...alert} from="layout" />}

      {isConstructionSelectorOpen && <ConstructionSelector onClose={() => setIsConstructionSelectorOpen(false)} />}
    </div>
  );
};
