import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import TooltipChibaContainer from "../../containers/matrix/TooltipChibaContainer";

class LeftGridCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tipSpecualName: null,
      tipEquipName: null,
    };
  }

  omitText(text) {
    if (text && text.length > 40) {
      return `${text.substr(0, 40)}...`;
    }

    return text;
  }

  omitTextChiba(text) {
    if (text && text.length > 30) {
      return `${text.substr(0, 30)}...`;
    }

    return text;
  }

  isOmit(text, validLength) {
    return text && text.length > validLength ? true : false;
  }

  handleOnMouse(columnValue, validLength) {
    if (this.isOmit(columnValue, validLength)) {
      if (this.props.cellType === "special") {
        this.setState({ tipSpecualName: { value: columnValue }, tipEquipName: null });
      } else {
        this.setState({ tipEquipName: { value: columnValue }, tipSpecualName: null });
      }

      ReactTooltip.rebuild();
    } else {
      this.setState({ tipSpecualName: null, tipEquipName: null });
    }
  }

  handleOnLeave() {
    this.setState({ tipSpecualName: null, tipEquipName: null });
  }

  render() {
    const { row, cellType } = this.props;

    let processNameJoined = "";
    if (row.special_processes) {
      processNameJoined = row.special_processes
        .map((process) => {
          return process.special_process_name;
        })
        .join("\n");
    }
    let equipNameJoined = "";
    if (row.protective_equipments) {
      equipNameJoined = row.protective_equipments
        .map((equip) => {
          return equip.protective_equipment_id == 9
            ? equip.protective_equipment_name + "（" + equip.protective_equipment_other_name + "）"
            : equip.protective_equipment_name;
        })
        .join("\n");
    }

    return (
      <React.Fragment>
        {cellType === "special" ? (
          <td
            data-tip
            data-for="matrix-tool-chiba"
            onMouseEnter={() => this.handleOnMouse(processNameJoined, 30)}
            onMouseLeave={() => this.handleOnLeave()}
            className="col-special-work"
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner txt-left p-5 wrap">{this.omitTextChiba(processNameJoined)}</div>
              </div>
            </div>
            {this.state.tipSpecualName !== null && (
              <ReactTooltip
                id="matrix-tool-chiba"
                className="matrix-task-detail"
                delayShow={500}
                effect="solid"
                isCapture={true}
                scrollHide={true}
              >
                <TooltipChibaContainer data={this.state.tipSpecualName} />
              </ReactTooltip>
            )}
          </td>
        ) : (
          <td
            data-tip
            data-for="matrix-tool-chiba"
            onMouseEnter={() => this.handleOnMouse(equipNameJoined, 30)}
            onMouseLeave={() => this.handleOnLeave()}
            className="col-protection"
          >
            <div className="grid-cell-wrap">
              <div className="grid-cell-con">
                <div className="grid-cell-inner txt-left p-5 wrap">{this.omitTextChiba(equipNameJoined)}</div>
              </div>
            </div>
            {this.state.tipEquipName !== null && (
              <ReactTooltip
                id="matrix-tool-chiba"
                className="matrix-task-detail"
                delayShow={500}
                effect="solid"
                isCapture={true}
                scrollHide={true}
              >
                <TooltipChibaContainer data={this.state.tipEquipName} />
              </ReactTooltip>
            )}
          </td>
        )}
      </React.Fragment>
    );
  }
}

LeftGridCell.propTypes = {
  cellType: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
};

export default withTranslation()(LeftGridCell);
