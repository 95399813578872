import clsx from "clsx";
import React, { FC } from "react";

type Props = {
  children: React.ReactNode;
  className?: string; // gridTemplateColumnsなどを指定する
  display?: boolean;
};

export const FormGrid: FC<Props> = ({ children, className, display }) => {
  return <div className={clsx("grid gap-y-[16px]", display === false ? "hidden" : "", className)}>{children}</div>;
};
