import { connect } from "react-redux";

import actions from "../actions/index";
import Navigation from "../components/Navigation";
import { RootState } from "@/reducers/types";

const mapStateToProps = (state: RootState) => {
  return {
    menus: state.app.menus,
    menu: state.app.menu,
    session: state.session,
    funcLayout: state.construction?.construction?.func_layout,
    funcProgress: state.construction?.construction?.func_progress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchSystem: (system) => dispatch(actions.app.switchSystem(system)),
    changeMenu: (menu) => {
      dispatch(actions.construction.refetchMasters());
      dispatch(actions.app.changeMenu(menu));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
