import * as constants from "../constants";

import storageManager from "@/lib/storageManager";

export type sessionState = {
  valid: boolean;
  userId: number;
  userName: string;
  shouldLogout: boolean;
  system: string;
};

const initialState: sessionState = {
  valid: false,
  userId: 0,
  userName: "",
  shouldLogout: false,
  system: "", // layout or progress
};

const authenticatedDataJSON = localStorage.getItem("authenticatedData");

if (authenticatedDataJSON !== null) {
  const authenticatedData = JSON.parse(authenticatedDataJSON);

  initialState.valid = true;
  initialState.userId = authenticatedData.user_id;
  initialState.userName = authenticatedData.user_name;

  const defaultSystem = storageManager.getUserItem("system") ?? "progress";
  if (defaultSystem) {
    initialState.system = defaultSystem;
  }
}

const session = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN_SUCCESS: {
      return {
        valid: true,
        userId: action.payload.user_id,
        userName: action.payload.user_name,
        shouldLogout: false,
        system: action.payload.system,
      };
    }
    case constants.APP_SWITCH_SYSTEM: {
      return {
        ...state,
        system: action.payload,
      };
    }
    case constants.LOGOUT_SUCCESS: {
      return {
        ...state,
        valid: false,
        userId: 0,
        userName: "",
        shouldLogout: false,
      };
    }
    case constants.LOGIN_SHOULD_LOGOUT: {
      return {
        ...state,
        shouldLogout: true,
      };
    }
    default:
      return state;
  }
};

export default session;
