import moment from "moment";
import React, { FC, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import { PlanListContents } from "./PlanListContents";
import { PlanListHeader } from "./PlanListHeader";

import { SCHEDULE_DATA_PER_PAGE } from "@/components/list/Schedule/constants";
import { useNoteEditor } from "@/components/list/Schedule/hooks/useNoteEditor";
import { UseSearchInputType } from "@/components/list/Schedule/hooks/useSearchInput";
import {
  BulkUpdateOption,
  ContinuousUpdateStatusType,
  ScheduleConfig,
  ScheduleSchedule,
} from "@/components/list/Schedule/types";
import { RootState } from "@/reducers/types";
import { FooterPager } from "@/components/common/FooterPager";

type Props = {
  data?: ScheduleSchedule;
  fetching: boolean;
  scheduleDate: Date;
  // TODO conditionはRedux stateに移動する
  condition: UseSearchInputType;
  bulkUpdateOptions?: BulkUpdateOption[];
  scheduleConfig?: ScheduleConfig;
  total: number;
  continuousUpdateStatus: ContinuousUpdateStatusType;
  onSearch: (option?: { page?: number; isPartialUpdate?: boolean; preventRefresh?: boolean }) => void;
  onPrevPage: () => void;
  onNextPage: () => void;
  onJumpPage: (page) => void;
};

const PlanList: FC<Props> = ({
  data,
  fetching,
  scheduleDate,
  condition,
  bulkUpdateOptions,
  scheduleConfig,
  total,
  continuousUpdateStatus,
  onSearch,
  onPrevPage,
  onNextPage,
  onJumpPage,
}) => {
  const { currentPageOfScheduleList } = useSelector((state: RootState) => state.schedule);
  const [page, pageTotal] = useMemo(() => {
    return [total === 0 ? 0 : currentPageOfScheduleList, Math.ceil(total / SCHEDULE_DATA_PER_PAGE)];
  }, [total, currentPageOfScheduleList]);

  const [tooltipText, setTooltipText] = useState(null);

  const noteEditor = useNoteEditor();

  const headerDateLabel = useMemo(() => {
    return moment(scheduleDate).format("MM/DD");
  }, [scheduleDate]);

  return (
    <div className="schedule-table-container" style={{ width: "calc(100% - 445px)" }}>
      <PlanListHeader
        headerDateLabel={headerDateLabel}
        totalWorkers={data?.total_worker_num}
        maxOvertime={Number(data?.max_overtime) ?? 0}
      />

      <PlanListContents
        bulkUpdateOptions={bulkUpdateOptions}
        condition={condition}
        continuousUpdateStatus={continuousUpdateStatus}
        currentPageOfScheduleList={currentPageOfScheduleList}
        data={data}
        fetching={fetching}
        scheduleDate={scheduleDate}
        scheduleConfig={scheduleConfig}
        total={total}
        setTooltipText={setTooltipText}
        onSearch={onSearch}
      />

      <div className="paging-footer">
        <FooterPager
          currentPage={page}
          maxPage={pageTotal}
          onPrev={onPrevPage}
          onNext={onNextPage}
          onJump={onJumpPage}
        />
      </div>

      <ReactTooltip
        id="schedule-cell-tooltip"
        className="matrix-task-detail"
        delayShow={500}
        effect="solid"
        offset={{ top: -5 }}
        getContent={() =>
          !tooltipText || noteEditor.id ? (
            <></>
          ) : (
            <div role="tooltip" className="schedule-tip in tooltip">
              <div className="tooltip-inner">
                <div className="form-txt wrap">{tooltipText}</div>
              </div>
            </div>
          )
        }
      />
    </div>
  );
};

export default PlanList;
