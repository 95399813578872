import clsx from "clsx";
import { FC } from "react";

type Props = {
  checked: boolean;
  label: string;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  className?: string;
};

export const CheckBox: FC<Props> = ({ checked, label, onChange, disabled, className, ...props }) => {
  return (
    <label className={clsx("checkbox", className)} {...props}>
      <input type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} disabled={disabled} />
      <span>{label}</span>
    </label>
  );
};
