import * as constants from "../constants/index";
import environments from "../environments";
import * as util from "../lib/common";
import { Construction, Customer, Place } from "@/models/construction";

export const save = (constructionId) => {
  util.setConstructionId(constructionId);

  return { type: constants.CONSTRUCTION_SAVE };
};

export const fetchItems = (
  callback: (response: { constructions: Construction[]; customers: Customer[]; places: Place[] }) => void
) => {
  const endpoint = `${environments.API_BASE_URI}/constructions`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.CONSTRUCTION_BEGIN_FETCH_ITEMS,
        };
      },
      success: (response) => {
        callback?.(response);

        return {
          type: constants.CONSTRUCTION_END_FETCH_ITEMS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchMasters = (callback) => {
  const endpoint = `${environments.API_BASE_URI}/construction/progress_masters`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.CONSTRUCTION_BEGIN_FETCH_MASTERS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.CONSTRUCTION_END_FETCH_MASTERS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const refetchMasters = () => {
  const endpoint = `${environments.API_BASE_URI}/construction/progress_masters`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      success: (response) => {
        return {
          type: constants.CONSTRUCTION_END_FETCH_MASTERS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchProgressRate = (callback) => {
  const endpoint = `${environments.API_BASE_URI}/progress_rate`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.CONSTRUCTION_BEGIN_FETCH_PROGRESS_RATE,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.CONSTRUCTION_END_FETCH_PROGRESS_RATE,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchExtensions = (callback) => {
  const endpoint = `${environments.API_BASE_URI}/construction/extensions`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.CONSTRUCTION_BEGIN_FETCH_EXTENSIONS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.CONSTRUCTION_END_FETCH_EXTENSIONS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};

export const fetchLinkageTasks = (callback) => {
  const endpoint = `${environments.API_BASE_URI}/construction/linkage_tasks`;

  return {
    type: constants.APP_CALL_API,
    endpoint,
    method: "GET",
    callbacks: {
      begin: () => {
        return {
          type: constants.CONSTRUCTION_BEGIN_FETCH_LINKAGE_TASKS,
        };
      },
      success: (response) => {
        if (callback !== undefined) {
          callback(response);
        }

        return {
          type: constants.CONSTRUCTION_END_FETCH_LINKAGE_TASKS,
          payload: response,
        };
      },
      error: (response) => {
        return {
          type: constants.APP_SHOW_ERROR,
          payload: response,
          error: true,
        };
      },
    },
  };
};
