import clsx from "clsx";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  isExpanded: boolean;
  onChange: (expanded: boolean) => void;
};

export const SearchConditionDisplayButton: FC<Props> = ({ isExpanded, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <button
      data-test-id="button-toggle-search-condition"
      className={clsx("btn h-[20px]", isExpanded ? "btn-gray" : "btn-light-blue")}
      style={{ padding: "0 0.75rem" }}
      onClick={() => onChange(!isExpanded)}
    >
      {isExpanded ? t("hide_unnecessary_condition") : t("show_all_condition")}
    </button>
  );
};
